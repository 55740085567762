import React from "react";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";

const VendorCouponAdd = (props) => {
  return (
    <div>
      <Modal
        show={props.vendorCouponAddModal}
        onHide={() =>
          props.setVendorCouponAddModal(!props.vendorCouponAddModal)
        }
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal-header">Add/Update Coupon</div>
        <Modal.Body>
          <div className="col-12">
            <span className="col-12">Code</span>
            <input
              type="text"
              id="code"
              className="col-12 email-input"
              onChange={(e) => props.setNewVendorCoupon(e)}
              value={props.currentVendorCoupon.code}
              placeholder="Coupon Code"
            />
          </div>
          <br />

          <div className="col-12">
            <span className="col-12">Description</span>
            <input
              type="text"
              className="col-12 email-input"
              id="description"
              onChange={(e) => props.setNewVendorCoupon(e)}
              value={props.currentVendorCoupon.description}
              placeholder="Coupon Description"
            />
          </div>
          <br />

          <div className="col-12">
            <span className="col-12">No. of Coupons</span>
            <input
              type="number"
              id="no_of_coupons"
              className="col-12 email-input"
              onChange={(e) => props.setNewVendorCoupon(e)}
              value={props.currentVendorCoupon.no_of_coupons}
              placeholder="No. of Coupons"
            />
          </div>
          <br />

          <div className="col-12">
            <span className="col-12">Auto Approval</span>
            <input
              type="checkbox"
              id="auto_approval"
              name="auto_approval"
              value="auto_approval"
              checked={props.currentVendorCoupon.auto_approval}
              onChange={(e) => props.setNewVendorCoupon(e)}
            />
          </div>
          <br />
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() =>
              props.setVendorCouponAddModal(!props.vendorCouponAddModal)
            }
          >
            Close
          </Button>
          <Button
            variant="success"
            onClick={() => props.onSendUpdateCoupon()}
            style={{ width: 150 }}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default VendorCouponAdd;
