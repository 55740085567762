import React, { useState } from "react";
import AdditionalReading from "./AdditionalReading";

let BASE_URL = "https://production.drfever.co/api";
let BASE_DOMAIN = "https://production.drfever.co";
const DeviceReading = (props) => {
  console.log(props.consumerSetupDeviceReadingKeys)
  return (
    props.deviceDefaultTransaction && (
      <div className="col-12">
        {props.consumerSetupDeviceReading.show && (
          <div>
            <br />
            <br />
            <h4>{props.consumerSetupDeviceReading.title} &nbsp;&nbsp;</h4>
            <ul>
              {props.consumerSetupDeviceReadingKeys.map((device_reading_key, index) => (
                <li key={device_reading_key}>
                  {props.consumerSetupDeviceReadingKeys[index]}: {props.deviceDefaultTransaction[device_reading_key]}
                </li>
              ))}
            </ul>
          </div>
        )}

        {props.consumerSetupAdditionalDeviceReading.show && (
          <div>
            <AdditionalReading consumerID={props.consumerID} deviceDefaultTransaction={props.deviceDefaultTransaction} consumerSetupAdditionalDeviceReading={props.consumerSetupAdditionalDeviceReading} />
          </div>
        )}
      </div>
    )
  );
};

export default DeviceReading;
