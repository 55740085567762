import React from 'react';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CreatableSelect from "react-select/creatable";

const SymptomSearch = (props) => {
    return (
        <div>
          <Modal show={props.symptomsSearchModal} 
                onHide={() => props.setSymptomsSearchModal(!props.symptomsSearchModal)}

            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered>

            <div className="modal-header">
              <h5 className="modal-title" id="">Symptoms: {props.symptomsSearchDetails.symptom}</h5>
            </div>

            <Modal.Body>

              <Row>
                <Col>
                  <img width="150px" 
                      height="100px"
                    src={props.symptomsSearchDetails.symptom_image} />
                </Col>

              </Row>
              <hr/>

              <h5>Recommended Medicine</h5>
              <Row>
                {
                  props.symptomsSearchDetails.recommended_drugs.map(recommended_drug => (
                    <Col key={recommended_drug.name}>
                      <div className="form-group">
                        <img width="150px" 
                            height="100px"
                          src={recommended_drug.image} />
                      </div>
                      <label>{recommended_drug.name}</label> <br/>
                      <label>Price: {recommended_drug.price}</label> <br/>
                      <label>Cured: {recommended_drug.cured}</label>
                    </Col>
                  ))
                }
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button size="sm" variant="secondary" onClick={() => props.setSymptomsSearchModal(!props.symptomsSearchModal)}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
    )
}

export default SymptomSearch;