/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import "../assets/css/main.css";
import menu from "../assets/images/menu.png";
import Login from "./Login";
import Registration from "./Registration";
import { AiFillSetting, AiOutlineSearch } from "react-icons/ai";
import "bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import SymptomSearch from "./SymptomSearch";
import { FiShoppingCart } from "react-icons/fi";
import { AiOutlineBell } from "react-icons/ai";
import { Button, ButtonGroup, Dropdown } from "react-bootstrap";
import { IoExitOutline } from "react-icons/io5";
import { CgProfile } from "react-icons/cg";
import Sidebar from "./Sidebar/Sidebar";
import { render } from "@testing-library/react";

let BASE_URL = "https://production.drfever.co/api";

const Navbar = (props) => {
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [LoginModal, setLoginModal] = useState(false);
  const [registerModal, setRegisterModal] = useState(false);
  const isAuthenticated = sessionStorage.getItem("isAuthenticated");
  const [searchText, setSearchText] = useState("");
  const [symptomsSearchModal, setSymptomsSearchModal] = useState(false);
  const [symptomsSearchDetails, setSymptomsSearchDetails] = useState({ recommended_drugs: [] });
  const [notification, setNotification] = useState(false);
  const [profile, setProfile] = useState(false);

  const onSearchSubmit = () => {
    let url = `${BASE_URL}/search/${searchText}/`;
    axios
      .get(url)
      .then((res) => {
        let response_data = res.data;
        setSymptomsSearchDetails(response_data);
        setSymptomsSearchModal(true);
      })
      .catch((e) => {
        alert("Search not found.");
        console.log("e", e);
      });
  };
  const openLoginModal = () => {
    setLoginModal(true);
  };

  const openRegisterModal = () => {
    setRegisterModal(true);
  };

  return (
    <>
      <SymptomSearch symptomsSearchModal={symptomsSearchModal} setSymptomsSearchModal={setSymptomsSearchModal} symptomsSearchDetails={symptomsSearchDetails} />
      <nav className="navigation ">
        {!isAuthenticated && <h1 className="brand-name">Dr. Fever</h1>}
        {isAuthenticated && (
          <div className="input-group mx-3 search-bar" >
            <span className="input-group-text" onClick={() => onSearchSubmit()} id="basic-addon1" style={{ borderRight: "hidden" }}>
              <AiOutlineSearch />
            </span>
            <input
              type="text"
              value={searchText}
              onChange={(e) => setSearchText(e.target.value)}
              className="form-control  "
              placeholder="Search Symptoms"
              aria-label="Search Symptoms"
              aria-describedby="basic-addon1"
              onKeyDown={(e) => {
                if (e.code === "Enter") {
                  e.preventDefault();
                  onSearchSubmit();
                }
              }}
            />
          </div>
        )}
        <button
          className="hamburger"
          onClick={() => {
            if(sessionStorage.getItem("isAuthenticated")){
            props.setShow(!props.show)
            render(<Sidebar/>)
            }else{
              setIsNavExpanded(!isNavExpanded)
            }
          }}
        >
          <img alt="menu" src={menu} style={{ height: 20, width: 20, marginBottom: 5 }} />
        </button>
        <div className={isNavExpanded ? "navigation-menu expanded" : "navigation-menu"}>
          <ul style={{ listStyle: "none", marginTop: "20px" }}>
            {!isAuthenticated && (
              <>
                <li>
                  <a href="#shop">Shop</a>
                </li>
                <Login LoginModal={LoginModal} openLoginModal={openLoginModal} setLoginModal={setLoginModal} setRegisterModal={setRegisterModal} />
                <Registration registerModal={registerModal} setRegisterModal={setRegisterModal} setLoginModal={setLoginModal} openRegisterModal={openRegisterModal} />
              </>
            )}
            {isAuthenticated && (
              <>
                <div className="d-flex align-items-center">
                  <a className="text-reset me-3" href="#">
                    <FiShoppingCart />
                  </a>
                  <Dropdown>
                    <Dropdown.Toggle variant="ghost" id="dropdown-basic" style={{marginBottom: "25px",width: "50px", height: "30px"}} className= "downArrow highlight">
                      <AiOutlineBell style={{ width: "20px", height: "20px"}} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item href="#/action-1">notification1</Dropdown.Item>
                      <Dropdown.Item href="#/action-2">notification2</Dropdown.Item>
                      <Dropdown.Item href="#/action-3">notification3</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                <Dropdown as={ButtonGroup} style={{ width: "150px", marginBottom: "16px" }}>
                  <Button variant="ghost" style={{ fontSize: "14px", color: "black" }} className = "highlight">
                    Hi, {sessionStorage.getItem("first_name")}
                  </Button>
                  <Dropdown.Toggle split variant="ghost" id="dropdown-custom-2" style={{ width: "20px", height: "50px" }} className = "highlight" />
                  <Dropdown.Menu className="super-colors">
                    <Dropdown.Item href="#/action-1">
                      <CgProfile />
                      Profile
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2">
                      <AiFillSetting /> Settings
                    </Dropdown.Item>

                    <Dropdown.Divider />
                    <Dropdown.Item href="/" onClick={() => sessionStorage.clear()}>
                      {" "}
                      <IoExitOutline /> Log out
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </>
            )}
          </ul>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
