import React from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const BusinessSetupDeviceReading = (props) => {
  return (
    <div>
      <Container>
        <Row>
          <b>
            <a
              href="#showDeviceReading"
              id="showDeviceReading"
              onClick={(e) => props.handleCollapseState(e)}
            >
              Device Reading {props.showDeviceReading}
            </a>
          </b>
          <br />
          <br />
        </Row>
        {props.showDeviceReading && (
          <Row>
            <Col sm={2}>
              <h6>Show</h6>
            </Col>
            <Col sm={2}>
              <input
                type="checkbox"
                id="show"
                name="image"
                value="Image"
                checked={props.consumerSetupDeviceReading.show}
                onChange={(e) => props.handleChange(e)}
              />
            </Col>
          </Row>
        )}
        {props.showDeviceReading && (
          <Row>
            <span>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Label #</th>
                    <th>Default</th>
                    <th>Custom</th>
                  </tr>
                </thead>
                <tbody>
                  {props.consumerSetupDeviceReadingKeys.map(
                    (device_reading_key, index) => (
                      <tr key={`device_reading_${device_reading_key}`}>
                        <td>{index + 1}</td>
                        <td>
                          {
                            props.consumerSetupDeviceReadingDefaults[
                              device_reading_key
                            ]
                          }
                        </td>
                        <td>
                          <input
                            type="text"
                            id={device_reading_key}
                            defaultValue={
                              props.consumerSetupDeviceReading[
                                device_reading_key
                              ]
                            }
                            onChange={(e) => props.handleChange(e)}
                            className="email-input"
                            placeholder={`${props.consumerSetupDeviceReadingDefaults[device_reading_key]} Custom Label`}
                          />
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
            </span>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default BusinessSetupDeviceReading;
