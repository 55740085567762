import axios from "axios";
import React, { useState } from "react";
import { Button, Table } from "react-bootstrap";
import { BiDevices } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

let BASE_URL = "https://production.drfever.co/api";

function ConsumerDeviceList() {
  const [serialNumber, setSerialNumber] = useState("");
  const consumerSetupDeviceList = JSON.parse(sessionStorage.getItem("device_list"))
  const navigate = useNavigate();
  console.log(JSON.parse(sessionStorage.getItem("symptoms")).title);
  const setDeviceData = (data, from_consumer_or_vendor) => {
    sessionStorage.setItem("deviceDefaultTransaction", JSON.stringify(data.device_default_transaction));
    sessionStorage.setItem("deviceTransactions", JSON.stringify(data.device_transactions));
    sessionStorage.setItem("default_consumer", data.default_consumer);
    console.log(data.assigned_coupons);
  };

  const onVerify = (serial_number, from_consumer_or_vendor, consumer_record_only) => {
    if (!serial_number) {
      serial_number = serialNumber;
    }

    if (from_consumer_or_vendor) {
      sessionStorage.setItem("serialNumber", serial_number);
    }

    const url = `${BASE_URL}/device/${serial_number}/`;
    axios
      .get(url)
      .then((res) => {
        let response_data = res.data;
        if (response_data.type === "device") {
          if (from_consumer_or_vendor) {
            setDeviceData(response_data.data);
            if (consumer_record_only) {
            } else {
              if (response_data.account_setup) {
                sessionStorage.setItem("device_reading", JSON.stringify(response_data.account_setup.device_reading));
                sessionStorage.setItem("device_reading_keys", JSON.stringify(response_data.account_setup.device_reading_keys));
                sessionStorage.setItem("additional_device_reading", JSON.stringify(response_data.account_setup.additional_device_reading));
                sessionStorage.setItem("additional_device_reading_keys", JSON.stringify(response_data.account_setup.additional_device_reading_keys));
                sessionStorage.setItem("symptoms", JSON.stringify(response_data.account_setup.symptoms));
              }
            }
          } else {
            alert("Search not found.");
          }
        }
      })
      .catch((e) => {
        alert("Search not found.");
        console.log("e", e);
      });
  };
  return (
    <div>
      <div className="text-base text-black m-3 font-weight-bold">
        <BiDevices style={{ marginBottom: "5px", fontWeight: "bold" }} />{" "}
        <label to="/dashboard" style={{ fontWeight: "bold" }}>
          Devices
        </label>
      </div>
      <div className="m-4 rounded " style={{ backgroundColor: "#F6FFFf", width: "75%" }}>
        <Table responsive striped bordered hover size="lg">
          <thead>
            <tr>
              <th>{consumerSetupDeviceList.serial}</th>
              {!consumerSetupDeviceList.others_checked && <th>{consumerSetupDeviceList.others}</th>}

              {!consumerSetupDeviceList.vendor_checked && <th>{consumerSetupDeviceList.vendor}</th>}

              <th>{consumerSetupDeviceList.symptoms}</th>
            </tr>
          </thead>
          <tbody>
            {JSON.parse(sessionStorage.getItem("assigned_devices")).map((assignedDevice) => (
              <tr key={assignedDevice.api_key}>
                {!assignedDevice.default_consumer_device && <td>{assignedDevice.serial_number}</td>}
                {assignedDevice.default_consumer_device && <td>Default</td>}

                {!consumerSetupDeviceList.others_checked && <td>{assignedDevice.default_consumer_device && <span>Default</span>}</td>}

                {!consumerSetupDeviceList.vendor_checked && <td>{assignedDevice.vendor_email}</td>}

                {assignedDevice.has_transactions && (
                  <td>
                    <Button
                      className="btn-sm"
                      variant="primary"
                      onClick={() => {
                        onVerify(assignedDevice.serial_number, true);
                        setTimeout(() => navigate("/recommendation"), 1000);
                      }}
                    >
                      Show
                    </Button>
                  </td>
                )}
                {!assignedDevice.has_transactions && (
                  <td>
                    <Button className="btn-sm" variant="dark">
                      No Reading
                    </Button>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default ConsumerDeviceList;
