import React from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const BusinessSetupAddlDeviceReading = (props) => {
  return (
    <div>
      <Container>
        <Row>
          <b>
            <a
              href="#showAddlDeviceReading"
              id="showAddlDeviceReading"
              onClick={(e) => props.handleCollapseState(e)}
            >
              Additional Reading {props.showAddlDeviceReading}
            </a>
          </b>
          <br />
          <br />
        </Row>
        {props.showAddlDeviceReading && (
          <Row>
            <Col sm={2}>
              <h6>Show</h6>
            </Col>
            <Col sm={2}>
              <input
                type="checkbox"
                id="show"
                name="image"
                value="Image"
                checked={props.consumerSetupAdditionalDeviceReading.show}
                onChange={(e) => props.additonalReadingHandleChange(e)}
              />
            </Col>
          </Row>
        )}
        {props.showAddlDeviceReading && (
          <Row>
            <span>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Label #</th>
                    <th>Default</th>
                    <th>Custom</th>
                  </tr>
                </thead>
                <tbody>
                  {props.consumerSetupAdditionalDeviceReadingKeys.map(
                    (device_reading_key, index) => (
                      <tr key={device_reading_key}>
                        <td>{index + 1}</td>
                        <td>
                          {
                            props.consumerSetupAdditionalDeviceReadingDefaults[
                              device_reading_key
                            ]
                          }
                        </td>
                        <td>
                          <input
                            type="text"
                            id={device_reading_key}
                            defaultValue={
                              props.consumerSetupAdditionalDeviceReading[
                                device_reading_key
                              ]
                            }
                            onChange={(e) =>
                              props.additonalReadingHandleChange(e)
                            }
                            className="email-input"
                            placeholder={`${props.consumerSetupAdditionalDeviceReadingDefaults[device_reading_key]} Custom Label`}
                          />
                          <br />
                          <label>
                            <input
                              type="checkbox"
                              id={`${device_reading_key}_checked`}
                              name={`${device_reading_key}_checked`}
                              value={`${device_reading_key}_checked`}
                              checked={
                                props.consumerSetupAdditionalDeviceReading[
                                  `${device_reading_key}_checked`
                                ]
                              }
                              onChange={(e) =>
                                props.additonalReadingHandleChange(e)
                              }
                            />
                            Hide
                          </label>
                        </td>
                      </tr>
                    )
                  )}
                </tbody>
              </Table>
            </span>
          </Row>
        )}
        {props.showAddlDeviceReading && (
          <Row>
            <span>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>File Type</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Image(jpg.gif,etc.)</td>
                    <td>
                      <input
                        type="checkbox"
                        id="attachment_image"
                        name="image"
                        value="Image"
                        checked={
                          props.consumerSetupAdditionalDeviceReading
                            .attachment_image
                        }
                        onChange={(e) => props.additonalReadingHandleChange(e)}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>Video(mp4,mov,etc.)</td>
                    <td>
                      <input
                        type="checkbox"
                        id="attachment_video"
                        name="attachment_video"
                        value="attachment_video"
                        checked={
                          props.consumerSetupAdditionalDeviceReading
                            .attachment_video
                        }
                        onChange={(e) => props.additonalReadingHandleChange(e)}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>Others</td>
                    <td>
                      <input
                        type="checkbox"
                        id="attachment_docs"
                        name="attachment_docs"
                        value="attachment_docs"
                        checked={
                          props.consumerSetupAdditionalDeviceReading
                            .attachment_docs
                        }
                        onChange={(e) => props.additonalReadingHandleChange(e)}
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </span>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default BusinessSetupAddlDeviceReading;
