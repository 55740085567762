import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import BusinessSetup from "../components/BusinessSetup";
import VendorCouponAdd from "../components/VendorCouponAdd";
import { FaEdit, FaPlusCircle } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";

const VendorCouponList = (props) => {
  return (
    <div>
      <Modal
        show={props.couponListModal}
        onHide={() => props.setCouponListModal(!props.couponListModal)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal-header">
          <div className="col-sm-6">
            <h5 className="modal-title" id="">
              {props.vendorFirstName} {props.vendorLastName} | Coupons
            </h5>
          </div>
          <div className="col-sm-1 col-sm-offset-5">
            <Button
              size="sm"
              variant="info"
              style={{ width: "60px" }}
              onClick={(e) => {
                props.setCurrentVendorCoupon({});
                props.setVendorCouponAddModal(true);
              }}
            >
              <FaPlusCircle />
            </Button>
          </div>
        </div>
        <Modal.Body>
          <Table striped bordered hover size="lg">
            <thead>
              <tr>
                <th>Code</th>
                <th>Descriptions</th>
                <th>No. of Coupons</th>
                <th>Available Coupons</th>
                <th>Auto Approval</th>
                <th>Edit</th>
                <th>Settings</th>
              </tr>
            </thead>
            <tbody>
              {props.assignedCoupons &&
                props.assignedCoupons.map((record) => (
                  <tr key={record.id}>
                    <td>
                      {record.code}
                    </td>
                    <td>{record.description}</td>
                    <td>{record.no_of_coupons}</td>
                    <td>{record.unused_coupons}</td>
                    <td>{record.auto_approval_display}</td>
                    <td>
                      <Button
                        size="sm"
                        variant="info"
                        style={{ width: "60px" }}
                        onClick={(e) => {
                          props.setCurrentVendorCoupon(record);
                          props.setVendorCouponAddModal(true);
                        }}
                      >
                        <FaEdit />
                      </Button>
                    </td>
                    <td>
                      <Button
                        size="sm"
                        variant="info"
                        style={{ width: "60px" }}
                        onClick={(e) => {
                          props.openAccountSetupModal(true, record)
                        }}
                      >
                        <FiSettings />
                      </Button>
                    </td>
                  </tr>
                ))}
            </tbody>
          </Table>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => props.setCouponListModal(!props.couponListModal)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {props.couponSetupModal && props.couponListModal && (
        <div className="col-sm-offset-2 col-sm-4">
          <h5 className="modal-title" id="">
            <BusinessSetup
              BASE_URL={props.BASE_URL}
              couponID={props.currentVendorCoupon.id}
              couponCode={props.currentVendorCoupon.code}
              yow={props.currentVendorCoupon}
              couponSetupModal={props.couponSetupModal}
              openAccountSetupModal={props.openAccountSetupModal}
              consumerSetupDeviceReading={
                props.currentVendorCoupon.account_setup.device_reading
              }
              consumerSetupAdditionalDeviceReading={
                props.currentVendorCoupon.account_setup
                  .additional_device_reading
              }
              consumerSetupDeviceReadingKeys={
                props.currentVendorCoupon.account_setup.device_reading_keys
              }
              consumerSetupAdditionalDeviceReadingKeys={
                props.currentVendorCoupon.account_setup
                  .additional_device_reading_keys
              }
              consumerSetupSymptoms={
                props.currentVendorCoupon.account_setup.symptoms
              }
              consumerSetupDeviceList={
                props.currentVendorCoupon.account_setup.device_list
              }
            />
          </h5>
        </div>
      )}

      <div className="col-sm-offset-2 col-sm-4">
        <h5 className="modal-title" id="">
          <VendorCouponAdd
            currentVendorCoupon={props.currentVendorCoupon}
            vendorCouponAddModal={props.vendorCouponAddModal}
            setVendorCouponAddModal={props.setVendorCouponAddModal}
            setNewVendorCoupon={props.setNewVendorCoupon}
            onSendUpdateCoupon={props.onSendUpdateCoupon}
          />
        </h5>
      </div>
    </div>
  );
};

export default VendorCouponList;
