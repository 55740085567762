import axios from 'axios';
import React, { useState } from 'react'
import { Button } from 'react-bootstrap';
import { FormProvider, useForm } from 'react-hook-form';
import { RiCoupon3Line } from 'react-icons/ri';
import { useLocation } from 'react-router-dom';
import { Error, SuccessMessage } from '../../helpers/FormHelpers';
import AdditionalReading from './CouponSetupTabs/AdditionalReading';
import DeviceDetails from './CouponSetupTabs/DeviceDetails';
import DeviceReading from './CouponSetupTabs/DeviceReading';
import Symptoms from './CouponSetupTabs/Symptoms';

let BASE_URL = "https://production.drfever.co/api";

function CouponSetup() {
    const methods = useForm()
    const [key, setKey] = useState("Device Reading")
    const location = useLocation()
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState(false)

    console.log(location.state.code + " " + location.state.id);

    const onAccountSetupSave = () => {
      let params = {
        account_setup: true,
        default_device_reading: {
          co2: methods.getValues("co2"),
          ethanol: methods.getValues("ethanol"),
          heart_rate: methods.getValues("heartRate"),
          pulse_rate: methods.getValues("pulseRate"),
          show: methods.getValues("showDeviceReading"),
          temperature: methods.getValues("temperature"),
          title: methods.getValues("deviceReadingTitle"),
          voc: methods.getValues("voc"),
        },
        additional_device_reading: {
          action: methods.getValues("action"),
          attachment: methods.getValues("attachment"),
          attachment_docs: methods.getValues("others"),
          attachment_image: methods.getValues("image"),
          attachment_video: methods.getValues("video"),
          data_created: methods.getValues("dataCreated"),
          description: methods.getValues("description"),
          show: methods.getValues("showAdditionalReading"),
          status: methods.getValues("status"),
          title: methods.getValues("addtionalReadingTitle"),
          user: methods.getValues("user"),
        },
        symptoms: {
          show: methods.getValues("showSymptoms"),
          title: methods.getValues("symptomTitle"),        
        },
        device_list: {
          others: methods.getValues("otherDetails"),
          others_checked: methods.getValues("otherChecked"),
          serial: methods.getValues("serial"),
          symptoms: methods.getValues("otherSymptoms"),
          title: methods.getValues("deviceDetailsTitle"),
          vendor: methods.getValues("vendor"),
          vendor_checked: methods.getValues("vendorChecked"), 
        }
      };
      let url = `${BASE_URL}/coupon/${location.state.id}/`;
      axios.put(url, params).then((res) => {
        console.log(params)
        setSuccess(true)
        window.location.reload();
      }).catch((e) => {
        setError(e.message)
        console.log(e)
      })
    };
  return (
    <FormProvider {...methods} >
      <form onSubmit={methods.handleSubmit((d) => {
        onAccountSetupSave()
        console.log(d);
      })}>
    <div>
      <div className="text-base text-black m-3 font-weight-bold">
      <RiCoupon3Line style={{ marginBottom: "5px", fontWeight: "bold" }} />{" "}
        <label to="/dashboard" style={{ fontWeight: "bold"}} >
          Coupon Setup
        </label>
      </div>
      <div className="m-4 rounded" style={{ backgroundColor: "#F6FFFf" }}>
        <div className="tabset">
          <input onChange={() => {}} type="radio" name="tabset" id="tab1" onClick={() => setKey("Device Reading")} aria-controls="device-reading" checked={key === "Device Reading"} />
          <label htmlFor="tab1">Device Reading</label>

          <input onChange={() => {}} type="radio" name="tabset" id="tab2" onClick={() => setKey("Additional Reading")} aria-controls="additional-reading" checked={key === "Additional Reading"} />
          <label htmlFor="tab2">Additional Reading</label>

          <input onChange={() => {}} type="radio" name="tabset" id="tab3" onClick={() => setKey("Symptoms")} aria-controls="symptoms" checked={key === "Symptoms"} />
          <label htmlFor="tab3">Symptoms</label>
          <input onChange={() => {}} type="radio" name="tabset" id="tab4" onClick={() => setKey("Other Details")} aria-controls="other-details" checked={key === "Other Details"} />
          <label htmlFor="tab4">Device Details</label>
          <br/>
         {success && <SuccessMessage message={"Data Saved Successfully!"}/>}
         {error && <Error message={error}/>}
          <div className="tab-panels">
            <section id="device-reading" className="tab-panel">
              <DeviceReading/>
            </section>
            <section id="additional-reading" className="tab-panel">
              <AdditionalReading/>
            </section>
            <section id="symptoms" className="tab-panel">
             <Symptoms/>
            </section>
            <section id="other-details" className="tab-panel">
              <DeviceDetails/>
            </section>
          </div>
        </div>
      </div>
      <div>
      <Button type="submit" variant="primary" className="m-4" style={{width: "200px"}}>Save Changes</Button>{' '}
      </div>
    </div>
    </form>
    </FormProvider>

  )
}

export default CouponSetup