import React from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

const BusinessSetupSymptoms = (props) => {
  return (
    <div>
      <Container>
        <Row>
          <b>
            <a
              href="#showSymptoms"
              id="showSymptoms"
              onClick={(e) => props.handleCollapseState(e)}
            >
              Symptoms {props.showSymptoms}
            </a>
          </b>
          <br />
          <br />
        </Row>
        {props.showSymptoms && (
          <Row>
            <Col sm={2}>
              <h6>Show</h6>
            </Col>
            <Col sm={2}>
              <input
                type="checkbox"
                id="show"
                name="image"
                value="Image"
                checked={props.consumerSetupSymptoms.show}
                onChange={(e) => props.symptomsHandleChange(e)}
              />
            </Col>
          </Row>
        )}
        {props.showSymptoms && (
          <Row>
            <span>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Label #</th>
                    <th>Default</th>
                    <th>Custom</th>
                  </tr>
                </thead>
                <tbody>
                  <tr key="symptoms_title">
                    <td>1</td>
                    <td>Title</td>
                    <td>
                      <input
                        type="text"
                        id="title"
                        defaultValue={props.consumerSetupSymptoms.title}
                        onChange={(e) => props.symptomsHandleChange(e)}
                        className="email-input"
                        placeholder="Title Custom Label"
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </span>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default BusinessSetupSymptoms;
