import axios from "axios";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { useForm } from "react-hook-form";
import { FaPlusCircle } from "react-icons/fa";
import { ErrorMessage } from "../../helpers/FormHelpers";
let BASE_URL = "https://production.drfever.co/api";

const ConsumerCouponAdd = (props) => {
  const [consumerCouponAddModal, setConsumerCouponAddModal] = useState(false);
  const [currentConsumerCoupon, setCurrentConsumerCoupon] = useState({});
  const {
    register,
    formState: { errors },
  } = useForm();
  function onSendUpdateConsumerCoupon() {
    currentConsumerCoupon["consumer"] = sessionStorage.getItem("id");
    let url = `${BASE_URL}/coupon/${currentConsumerCoupon.id}/`;
    axios
      .post(url, currentConsumerCoupon)
      .then((res) => {
        sessionStorage.setItem("assigned_coupons", JSON.stringify(res.data.data));
        setConsumerCouponAddModal(!consumerCouponAddModal);
      })
      .catch((e) => {
        if (e.response.status === 400) {
          alert(e.response.data);
        } else {
          alert("Something went wrong.");
        }
      });
  }

  function setNewConsumerCoupon(e) {
    let value = e.target.value;
    if (e.target.attributes.type.value === "checkbox") {
      value = e.target.checked;
    }

    setCurrentConsumerCoupon({
      ...currentConsumerCoupon,
      [e.target.id]: value,
    });
  }

  return (
    <div>
      <div className="modal-header">
        <div className="col-sm-1 col-sm-offset-5">
          <Button
            size="sm"
            variant="primary"
            style={{ width: "180px", height: "40px" }}
            onClick={(e) => {
              setConsumerCouponAddModal(!consumerCouponAddModal);
            }}
          >
            <FaPlusCircle /> Add New Coupon
          </Button>
        </div>
      </div>
      <div></div>
      <Modal show={consumerCouponAddModal} size="xs" onHide={() => setConsumerCouponAddModal(!consumerCouponAddModal)} aria-labelledby="contained-modal-title-vcenter" centered>
        <div className="modal-header" style={{ fontWeight: "bold" }}>
          Add New Coupon
        </div>
        <Modal.Body>
          <div className="m-3 mt-3 w-75 input-field">
            <label htmlFor="exampleInputEmail1" className="form-label">
              Code
            </label>
            <input type="text" onChange={(e) => setNewConsumerCoupon(e)} value={currentConsumerCoupon.code} {...register("code", { required: "Code is required" })} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Coupon Code" />
            {errors.code && <ErrorMessage message={errors.code.message} />}
          </div>

          <br />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="ghost" onClick={() => setConsumerCouponAddModal(!consumerCouponAddModal)}>
            Close
          </Button>
          <Button variant="primary" onClick={() => onSendUpdateConsumerCoupon()} style={{ width: 150 }}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ConsumerCouponAdd;
