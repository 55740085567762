import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FaEdit } from "react-icons/fa";
import { Error, SuccessMessage } from "../../helpers/FormHelpers";

let BASE_URL = "https://production.drfever.co/api";

function CouponEditModal(props) {
  const [currentVendorCoupon, setCurrentVendorCoupon] = useState({});
  const [vendorCouponEditModal, setVendorCouponEditModal] = useState(false);
  const [autoApproval, setAutoApproval] = useState(false);
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)

  const { register, handleSubmit, getValues, setValue } = useForm({ mode: "onSubmit" });
  useEffect(() => {
    setValue("code", props.code)
    setValue("description", props.description)
    setValue("no_of_coupons", props.no_of_coupons)
    setValue( "approve",props.auto_approval === "Yes" ? true : false)
    console.log(props.id)
  }, [])
  
  const onSendUpdateCoupon = () => {
    console.log("clicked");
    const couponData = {
      "id": props.id,
      "code": getValues("code"),
      "description": getValues("description"),
      "no_of_coupons": parseInt(getValues("no_of_coupons")),
      "auto_approval": autoApproval,
      "vendor": parseInt(sessionStorage.getItem("id")),
    };
    let url = `${BASE_URL}/coupon/`;
    axios
      .post(url, couponData)
      .then((res) => {
        // sessionStorage.setItem("assigned_coupons", JSON.stringify(res.data));
        setVendorCouponEditModal(!vendorCouponEditModal);
        setSuccess(true)
      })
      .catch((e) => {
        setError(e.message)
      });
  };
  return (
    <div>
      <Button
        size="sm"
        variant="ghost"
        onClick={(e) => {
          setCurrentVendorCoupon({});
          setVendorCouponEditModal(true);
        }}
      >
        <FaEdit />
      </Button>{" "}
      <div>
        <form name="form1" onSubmit={handleSubmit(onSendUpdateCoupon)}>
          <Modal show={vendorCouponEditModal} onHide={() => setVendorCouponEditModal(!vendorCouponEditModal)} aria-labelledby="contained-modal-title-vcenter" centered>
            <div className="modal-header">Update Coupon</div>
            <Modal.Body>
            {success && <SuccessMessage message={"Coupon Updated Successfully!"}/>}
         {error && <Error message={error}/>}
              <div className="col-12">
                <span className="col-12">Code</span>
                <input type="text" id="code" className="col-12 form-control" {...register("code", { required: false })} value={currentVendorCoupon.code} placeholder="Coupon Code" />
              </div>
              <br />

              <div className="col-12">
                <span className="col-12">Description</span>
                <input type="text" className="col-12 form-control" id="description" {...register("description", { required: false })} value={currentVendorCoupon.description} placeholder="Coupon Description" />
              </div>
              <br />

              <div className="col-12">
                <span className="col-12">No. of Coupons</span>
                <input type="number" id="no_of_coupons" className="col-12 form-control" {...register("no_of_coupons", { required: false })} value={currentVendorCoupon.no_of_coupons} placeholder="No. of Coupons" />
              </div>
              <br />

              <div className="col-12">
                <input type="checkbox" id="auto_approval" name="auto_approval" value="auto_approval" {...register("approve", { required: false })} onClick={() => setAutoApproval(!autoApproval)} />
                <span className="col-12"> Auto Approval</span>
              </div>
              <br />
            </Modal.Body>
            <form onSubmit={(e) => console.log(e)}>
              <Modal.Footer>
                <Button variant="ghost" onClick={() => setVendorCouponEditModal(!vendorCouponEditModal)}>
                  Close
                </Button>
                <Button variant="primary" type="submit" style={{ width: 150 }}>
                  Save Changes
                </Button>
              </Modal.Footer>
            </form>
          </Modal>
        </form>
      </div>
    </div>
  );
}

export default CouponEditModal;
