import React from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import Fade from "react-bootstrap/Fade";

import Button from "react-bootstrap/Button";
import BusinessSetupDeviceReading from "./BusinessSetupDeviceReading";
import BusinessSetupAddlDeviceReading from "./BusinessSetupAddlDeviceReading";
import BusinessSetupSymptoms from "./BusinessSetupSymptoms";
import BusinessSetupDeviceList from "./BusinessSetupDeviceList";

class BusinessSetup extends React.Component {
  state = {
    showDeviceReading: false,
    showAddlDeviceReading: false,
    showSymptoms: false,
    showDeviceList: false,
    showDialog: true,
    consumerSetupDeviceReading: {},
    consumerSetupDeviceReadingKeys: [],
    consumerSetupDeviceReadingDefaults: {
      title: "Title",
      heart_rate: "Heart Rate",
      pulse_rate: "Pulse Rate",
      voc: "VOC",
      h2o: "H2O",
      co2: "CO2",
      ethonol: "Ethonol",
      temperature: "Temperature",
    },
    consumerSetupAdditionalDeviceReadingDefaults: {
      title: "Title",
      description: "Description",
      attachment: "Attachment",
      user: "User",
      date_created: "Date Created",
      status: "Status",
      action: "ACtion",
    },
    consumerSetupAdditionalDeviceReading: {},
    consumerSetupAdditionalDeviceReadingKeys: [],
    consumerSetupSymptoms: {},
    consumerSetupDeviceList: {},
  };

  handleDialogState = (show) => {
    this.setState({
      ["showDialog"]: show,
    });
  };

  handleCollapseState = (e) => {
    this.setState({
      [e.target.id]: !this.state[e.target.id],
    });
  };

  initiate = () => {
    this.setState({
      ["consumerSetupDeviceReading"]: this.props.consumerSetupDeviceReading,
    });

    this.setState({
      ["consumerSetupDeviceReadingKeys"]:
        this.props.consumerSetupDeviceReadingKeys,
    });

    this.setState({
      ["consumerSetupAdditionalDeviceReading"]:
        this.props.consumerSetupAdditionalDeviceReading,
    });

    this.setState({
      ["consumerSetupAdditionalDeviceReadingKeys"]:
        this.props.consumerSetupAdditionalDeviceReadingKeys,
    });

    this.setState({
      ["consumerSetupSymptoms"]: this.props.consumerSetupSymptoms,
    });

    this.setState({
      ["consumerSetupDeviceList"]: this.props.consumerSetupDeviceList,
    });
  };

  handleChange = (e) => {
    let value = e.target.value;
    if (e.target.attributes.type.value === "checkbox") {
      value = e.target.checked;
    }

    this.setState({
      consumerSetupDeviceReading: {
        ...this.state.consumerSetupDeviceReading,
        [e.target.id]: value,
      },
    });
  };

  additonalReadingHandleChange = (e) => {
    let value = e.target.value;
    if (e.target.attributes.type.value === "checkbox") {
      value = e.target.checked;
    }

    this.setState({
      consumerSetupAdditionalDeviceReading: {
        ...this.state.consumerSetupAdditionalDeviceReading,
        [e.target.id]: value,
      },
    });
  };

  symptomsHandleChange = (e) => {
    let value = e.target.value;
    if (e.target.attributes.type.value === "checkbox") {
      value = e.target.checked;
    }

    this.setState({
      consumerSetupSymptoms: {
        ...this.state.consumerSetupSymptoms,
        [e.target.id]: value,
      },
    });
  };

  deviceListHandleChange = (e) => {
    let value = e.target.value;
    if (e.target.attributes.type.value === "checkbox") {
      value = e.target.checked;
    }

    this.setState({
      consumerSetupDeviceList: {
        ...this.state.consumerSetupDeviceList,
        [e.target.id]: value,
      },
    });
  };

  onAccountSetupSave = () => {
    let params = {
      account_setup: true,
    };
    params["default_device_reading"] = this.state.consumerSetupDeviceReading;
    params["additional_device_reading"] =
      this.state.consumerSetupAdditionalDeviceReading;
    params["symptoms"] = this.state.consumerSetupSymptoms;
    params["device_list"] = this.state.consumerSetupDeviceList;

    let url = `${this.props.BASE_URL}/coupon/${this.props.couponID}/`;
    axios.put(url, params).then((res) => {
      console.log(params)
      window.location.reload();
    });
  };

  render() {
    return (
      <div>
        <Modal
          show={this.props.couponSetupModal}
          onShow={() => this.initiate()}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className="modal-header">
            <div className="col-sm-12">
              <h5 className="modal-title" id="">
                {this.props.couponCode} | Account Setup
              </h5>
            </div>
          </div>
          <Modal.Body>
            <BusinessSetupDeviceReading
              showDeviceReading={this.state.showDeviceReading}
              handleCollapseState={this.handleCollapseState}
              consumerSetupDeviceReading={this.state.consumerSetupDeviceReading}
              handleChange={this.handleChange}
              consumerSetupDeviceReadingKeys={
                this.state.consumerSetupDeviceReadingKeys
              }
              consumerSetupDeviceReadingDefaults={
                this.state.consumerSetupDeviceReadingDefaults
              }
            />

            {/* Additional Reading */}
            <BusinessSetupAddlDeviceReading
              showAddlDeviceReading={this.state.showAddlDeviceReading}
              handleCollapseState={this.handleCollapseState}
              consumerSetupAdditionalDeviceReading={
                this.state.consumerSetupAdditionalDeviceReading
              }
              consumerSetupAdditionalDeviceReadingKeys={
                this.state.consumerSetupAdditionalDeviceReadingKeys
              }
              consumerSetupAdditionalDeviceReadingDefaults={
                this.state.consumerSetupAdditionalDeviceReadingDefaults
              }
              additonalReadingHandleChange={this.additonalReadingHandleChange}
            />

            <BusinessSetupSymptoms
              showSymptoms={this.state.showSymptoms}
              handleCollapseState={this.handleCollapseState}
              consumerSetupSymptoms={this.state.consumerSetupSymptoms}
              symptomsHandleChange={this.symptomsHandleChange}
            />

            <BusinessSetupDeviceList
              showDeviceList={this.state.showDeviceList}
              handleCollapseState={this.handleCollapseState}
              consumerSetupDeviceList={this.state.consumerSetupDeviceList}
              deviceListHandleChange={this.deviceListHandleChange}
            />
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => this.props.openAccountSetupModal(false, {})}
            >
              Close
            </Button>
            <Button
              variant="success"
              onClick={() => this.onAccountSetupSave()}
              style={{ width: 150 }}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default BusinessSetup;
