import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { AiOutlineHome } from "react-icons/ai";
import "./Profile.css";
import AccountSettings from "./AccountSettings";
import PersonalInformation from "./PersonalInformation";
import ContactInformation from "./ContactInformation";
import OtherDetails from "./OtherDetails";
import { Button } from "react-bootstrap";
import { FormProvider, useForm } from "react-hook-form";
import axios from "axios";

let BASE_URL = "https://production.drfever.co/api";

function Profile() {
  const [key, setKey] = useState("Account Settings");
  const methods = useForm()

  methods.setValue('first_name', sessionStorage.getItem('first_name'))
  methods.setValue('last_name', sessionStorage.getItem('last_name'))
  methods.setValue('email', sessionStorage.getItem('email'))
  methods.setValue('phone_number', sessionStorage.getItem('phone_number'))
  methods.setValue('otp', sessionStorage.getItem('bypass_otp'))

  const onSendUpdateVendorData = () => {
    axios
      .put(`${BASE_URL}/vendor/${sessionStorage.getItem('id')}/`, {
        email: methods.getValues('email'),
        first_name: methods.getValues('first_name'),
        last_name: methods.getValues('last_name'),
        phone_number: methods.getValues('phone_number'),
        bypass_otp: methods.getValues('otp'),
      })
      .then((res) => {
        alert(res.data.message);
      });
  };

  return (
    <FormProvider {...methods} >
      <form onSubmit={methods.handleSubmit((d) => {
        onSendUpdateVendorData()
        console.log(d);
      })}>
    <div>
      <div className="text-base text-black m-3 font-weight-bold">
        <AiOutlineHome style={{marginBottom: "5px", fontWeight: "bold"}} />{" "}
        <label to="/dashboard" style={{ fontWeight: "bold"}} >
          Dashboard
        </label>
      </div>
      <div className="m-4 rounded" style={{ backgroundColor: "#F6FFFf" }}>
        <div className="tabset">
        
          <input type="radio" onChange={() => {}} name="tabset" id="tab1" onClick={() => setKey("Account Settings")} aria-controls="account-settings" checked={key === "Account Settings"} />
          <label htmlFor="tab1">Account Settings</label>

          <input type="radio" onChange={() => {}} name="tabset" id="tab2" onClick={() => setKey("Personal Information")} aria-controls="personal-information" checked={key === "Personal Information"} />
          <label htmlFor="tab2">Personal Information</label>

          <input type="radio" onChange={() => {}} name="tabset" id="tab3" onClick={() => setKey("Contact Information")} aria-controls="contact-information" checked={key === "Contact Information"} />
          <label htmlFor="tab3">Contact Information</label>
          <input type="radio" onChange={() => {}} name="tabset" id="tab4" onClick={() => setKey("Other Details")} aria-controls="other-details" checked={key === "Other Details"} />
          <label htmlFor="tab4">Other Details</label>

          <div className="tab-panels">
            <section id="account-settings" className="tab-panel">
              <AccountSettings />
            </section>
            <section id="personal-information" className="tab-panel">
              <PersonalInformation />
            </section>
            <section id="contact-information" className="tab-panel">
              <ContactInformation />
            </section>
            <section id="other-details" className="tab-panel">
              <OtherDetails />
            </section>
          </div>
        </div>
      </div>
      <div>
      <Button type="submit" variant="primary" className="m-4" style={{width: "200px"}}>Save Changes</Button>{' '}
      </div>
    </div>
    </form>
    </FormProvider>

  );
}

export default Profile;
