import React, { useState } from "react";
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Navbar from "./components/navbar";
import DataDeletion from "./screens/dataDeletion";
import Detail from "./screens/Detail";
import Home from "./screens/Home";
import PrivacyPolicy from "./screens/PrivacyPolicy";
import TermsAndConditions from "./screens/TermsAndConditions";
import Sidebar from "./components/Sidebar/Sidebar";
import "bootstrap/dist/css/bootstrap.min.css";
import Dashboard from "./screens/Dashboard";
import ProtectedRoute from "./ProtectedRoute";
import Coupons from "./screens/Coupons";
import Devices from "./screens/Devices";
import Consumers from "./screens/Consumers";
import Setup from "./screens/Setup";
import Recommendations from "./screens/Recommendations";

const App = () => {
  const [show, setShow] = useState(false)
  return (
    <BrowserRouter>
      <div className="container-fluid sidebar">
        <div className="row" style={{ marign: 0 }}>
          <Sidebar show = {show} setShow = {setShow}/>
          <div className="col" style={{ padding: 0, gap: 0 }}>   
            <Navbar show = {show} setShow = {setShow}/>
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/" element={<ProtectedRoute />}>
                <Route exact path="/dashboard" element={<Dashboard />} />
                <Route exact path="/terms&conditions" element={<TermsAndConditions />} />
                <Route exact path="/delete-instruction" element={<DataDeletion />} />
                <Route exact path="/privacy" element={<PrivacyPolicy />} />
                <Route exact path="/detail" element={<Detail />} />
                <Route exact path="/coupons" element={<Coupons />} />
                <Route exact path="/devices" element={<Devices />} />
                <Route exact path="/consumers" element={<Consumers />} />
                <Route exact path="/coupon-setup" element={<Setup />} />
                <Route exact path="/recommendation" element={<Recommendations />} />

              </Route>
            </Routes>
          </div>
        </div>
      </div>
    </BrowserRouter>
  );
};

export default App;
