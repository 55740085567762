import React from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CreatableSelect from "react-select/creatable";
import { FaEdit, FaPlusCircle } from "react-icons/fa";

import DeviceReading from "../components/DeviceReading";
import TransactionAliasModal from "../components/TransactionAliasModal";
import NewTransactionModal from "../components/NewTransactionModal";

class ConsumerSymptoms extends React.Component {
  state = {
    transactionID: null,
    description: "",
    transactionAliasModal: false,
    transactionAliasInput: "",
    newTransactionModal: false,
    transactionSymptoms: [],

    heart_rate: "",
    pulse_rate: "",
    voc: "",
    co2: "",
    h2o: "",
    ethonol: "",
    temperature: "",
    actual_bp1: "",
    actual_bp2: "",
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleTransactionChange = (e, alias) => {
    this.setState({
      ["transactionID"]: e.value,
    });
    this.saveDefaultTransaction(e);
  };

  saveDefaultTransaction = (e, TransactionAlias) => {
    let params = {};
    if (TransactionAlias) {
      params["transactionID"] = this.state.transactionID;
      params["TransactionAlias"] = TransactionAlias;
    } else {
      params["transactionID"] = e.value;
    }

    let serialNumber = this.props.serialNumber;

    if (!serialNumber) {
      serialNumber = this.props.deviceDefaultTransaction.serial_number;
    }

    axios
      .post(`${this.props.BASE_URL}/device/${serialNumber}/`, params)
      .then((res) => {
        if (e) {
          this.props.deviceReadingOnChange(e);
          this.readConsumerSymptoms(e.value);
          console.log("--1");
        } else {
          this.setTransactionAliasModal(false);
          this.props.showConsumerDeviceList(this.props.serialNumber);
          this.props.setDeviceModal(false);

          console.log("--22");
        }
      });
  };

  addConsumerSymptoms = () => {
    this.setState({
      ["description"]: "",
    });

    axios
      .put(
        `${this.props.BASE_URL}/transaction_symptoms/${this.state.transactionID}/`,
        {
          description: this.state.description,
        }
      )
      .then((res) => {
        this.readConsumerSymptoms();
      });
  };

  readConsumerSymptoms = (transactionID) => {
    if (!transactionID) {
      transactionID = this.state.transactionID;
    }

    axios
      .get(`${this.props.BASE_URL}/transaction_symptoms/${transactionID}/`)
      .then((res) => {
        this.setState({
          ["transactionSymptoms"]: res.data,
        });
      });
  };

  deleteConsumerSymptoms = (transactionSymptomID) => {
    axios
      .delete(
        `${this.props.BASE_URL}/transaction_symptoms/${transactionSymptomID}/`
      )
      .then((res) => {
        this.readConsumerSymptoms();
      });
  };

  setTransactionAliasModal = (value) => {
    this.setState({
      ["transactionAliasInput"]: "",
    });

    this.setState({
      ["transactionAliasModal"]: value,
    });
  };

  setTransactionAlias = (value) => {
    this.setState({
      ["transactionAliasInput"]: value,
    });
  };

  setNewTransactionModal = (value) => {
    this.setState({
      ["newTransactionModal"]: value,
    });
  };

  saveNewTransaction = () => {
    let params = this.props.consumerDefaultDevice;
    params["data"] = {};
    let transaction_keys = [
      "heart_rate",
      "pulse_rate",
      "voc",
      "co2",
      "h2o",
      "ethonol",
      "temperature",
      "actual_bp1",
      "actual_bp2",
    ];

    for (var i in transaction_keys) {
      let transaction_key = transaction_keys[i];
      params["data"][transaction_key] = parseFloat(this.state[transaction_key]);
    }

    axios.post(`${this.props.BASE_URL}/transaction/`, params).then((res) => {
      this.setNewTransactionModal(false);
      this.props.setDeviceModal(!this.props.deviceModal);
      this.props.showConsumerDeviceList(params.serial_number);
    });
  };

  initiate = () => {
    this.setState({
      ["transactionID"]: this.props.deviceDefaultTransaction.value,
    });

    this.readConsumerSymptoms(this.props.deviceDefaultTransaction.value);
  };

  render() {
    return (
      <div>
        <Modal
          show={this.props.deviceModal && this.props.userType === "consumer"}
          onShow={() => this.initiate()}
          onHide={() => this.props.setDeviceModal(!this.props.deviceModal)}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <div className="modal-header">
            <h5 className="modal-title" id="">
              Welcome {this.props.consumerFirstName}{" "}
              {this.props.consumerLastName}!
            </h5>
            <a
              href="#"
              onClick={() => {
                this.props.setDeviceModal(!this.props.deviceModal);
                this.props.setDeviceConsumerListModal(
                  !this.props.deviceConsumerListModal
                );
              }}
            >
              Update Consumer Info
            </a>
          </div>
          <Modal.Body>
            <Container>
              <Row>
                <Col sm={4}>
                  <div className="col-12">
                    <CreatableSelect
                      placeholder="Device Reading"
                      isSearchable
                      isClearable
                      options={this.props.deviceTransactions}
                      defaultValue={this.props.deviceDefaultTransaction}
                      onChange={(e) => this.handleTransactionChange(e)}
                    />
                  </div>
                  <DeviceReading
                    userType={this.props.userType}
                    consumerSetupAdditionalDeviceReadingKeys={
                      this.props.consumerSetupAdditionalDeviceReadingKeys
                    }
                    consumerSetupAdditionalDeviceReading={
                      this.props.consumerSetupAdditionalDeviceReading
                    }
                    consumerSetupDeviceReadingKeys={
                      this.props.consumerSetupDeviceReadingKeys
                    }
                    consumerSetupDeviceReading={
                      this.props.consumerSetupDeviceReading
                    }
                    deviceDefaultTransaction={
                      this.props.deviceDefaultTransaction
                    }
                    consumerID={this.props.consumerID}
                    BASE_URL={this.props.BASE_URL}
                    BASE_DOMAIN={this.props.BASE_DOMAIN}
                  />
                </Col>
                <Col sm={1}>
                  <Button
                    size="sm"
                    variant="info"
                    style={{ width: "60px" }}
                    onClick={() => this.setTransactionAliasModal(true)}
                  >
                    <FaEdit />
                  </Button>
                </Col>
                <Col sm={1}>
                  {this.props.currentDeviceDefaultConsumer && (
                    <Button
                      size="sm"
                      variant="info"
                      style={{ width: "60px" }}
                      onClick={() => this.setNewTransactionModal(true)}
                    >
                      <FaPlusCircle />
                    </Button>
                  )}
                </Col>
                <Col sm={6}>
                  {this.props.consumerSetupSymptoms &&
                    this.props.consumerSetupSymptoms.show && (
                      <Table striped bordered hover size="lg">
                        <thead>
                          <tr>
                            <th>{this.props.consumerSetupSymptoms.title}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.transactionSymptoms.map(
                            (transactionSymptom) => (
                              <tr key={transactionSymptom.id}>
                                <td>{transactionSymptom.description}</td>
                                {this.props.userType == "consumer" && (
                                  <td>
                                    <Button
                                      size="sm"
                                      variant="danger"
                                      onClick={() =>
                                        this.deleteConsumerSymptoms(
                                          transactionSymptom.id
                                        )
                                      }
                                    >
                                      Delete
                                    </Button>
                                  </td>
                                )}
                              </tr>
                            )
                          )}
                          <tr>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Symptoms Description"
                                id="description"
                                value={this.state.description}
                                onChange={this.handleChange}
                              />
                            </td>
                            <td>
                              <Button
                                size="sm"
                                variant="success"
                                onClick={() => this.addConsumerSymptoms()}
                              >
                                Add
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    )}
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="sm"
              variant="secondary"
              onClick={() => this.props.setDeviceModal(!this.props.deviceModal)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <TransactionAliasModal
          deviceDefaultTransaction={this.props.deviceDefaultTransaction}
          transactionAliasModal={this.state.transactionAliasModal}
          setTransactionAliasModal={this.setTransactionAliasModal}
          setTransactionAlias={this.setTransactionAlias}
          saveTransactionAlias={this.saveTransactionAlias}
          transactionAliasInput={this.state.transactionAliasInput}
          saveDefaultTransaction={this.saveDefaultTransaction}
        />

        <NewTransactionModal
          newTransactionModal={this.state.newTransactionModal}
          setNewTransactionModal={this.setNewTransactionModal}
          state={this.state}
          handleChange={this.handleChange}
          saveNewTransaction={this.saveNewTransaction}
        />
      </div>
    );
  }
}

export default ConsumerSymptoms;
