import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

const NewTransactionModal = (props) => {
    return (
        <div>
          <Modal show={props.newTransactionModal} 
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered>
            <div className="modal-header">
              <h5 className="modal-title" id="">Add manual reading</h5>
            </div>
            <Modal.Body>
            <br/>
              <div>
                <label>Heart Rate</label>
                <input type="number" 
                    className="col-12 email-input" 
                    placeholder='Heart Rate'
                    onChange={(e) => props.handleChange(e)}
                    id="heart_rate"
                    value={props.state.heart_rate} />
              </div>

              <br/>
              <div>
                <label>Pulse Rate</label>
                <input type="number" 
                    className="col-12 email-input" 
                    placeholder='Pulse Rate'
                    onChange={(e) => props.handleChange(e)}
                    id="pulse_rate"
                    value={props.state.pulse_rate} />
              </div>

              <br/>
              <div>
                <label>VOC</label>
                <input type="number" 
                    className="col-12 email-input" 
                    placeholder='VOC'
                    onChange={(e) => props.handleChange(e)}
                    id="voc"
                    value={props.state.voc} />
              </div>

              <br/>
              <div>
                <label>CO2</label>
                <input type="number" 
                    className="col-12 email-input" 
                    placeholder='CO2'
                    onChange={(e) => props.handleChange(e)}
                    id="co2"
                    value={props.state.co2} />
              </div>

              <br/>
              <div>
                <label>H20</label>
                <input type="number" 
                    className="col-12 email-input" 
                    placeholder='H20'
                    onChange={(e) => props.handleChange(e)}
                    id="h2o"
                    value={props.state.h2o} />
              </div>

              <br/>
              <div>
                <label>Ethonol</label>
                <input type="number" 
                    className="col-12 email-input" 
                    placeholder='Ethonol'
                    onChange={(e) => props.handleChange(e)}
                    id="ethonol"
                    value={props.state.ethonol} />
              </div>

              <br/>
              <div>
                <label>Temparature</label>
                <input type="number" 
                    className="col-12 email-input" 
                    placeholder='Temparature'
                    onChange={(e) => props.handleChange(e)}
                    id="temperature"
                    value={props.state.temperature} />
              </div>

              <br/>
              <div>
                <label>Actual BP1</label>
                <input type="number" 
                    className="col-12 email-input" 
                    placeholder='Actual BP1'
                    onChange={(e) => props.handleChange(e)}
                    id="actual_bp1"
                    value={props.state.actual_bp1} />
              </div>

              <br/>
              <div>
                <label>Actual BP2</label>
                <input type="number" 
                    className="col-12 email-input" 
                    placeholder='Actual BP2'
                    onChange={(e) => props.handleChange(e)}
                    id="actual_bp2"
                    value={props.state.actual_bp2} />
              </div>

            </Modal.Body>
            <Modal.Footer>
              <Button size="sm" variant="secondary" onClick={() => props.setNewTransactionModal(false)}>
                Close
              </Button>
              <Button size="sm" variant="primary" onClick={() => props.saveNewTransaction()}>
                Save
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
    )
}

export default NewTransactionModal;