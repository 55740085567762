import React from "react";
import ConsumerDeviceList from "../components/DeviceList/ConsumerDeviceList";
import DeviceList from "../components/DeviceList/DeviceList";

function Devices() {
  const userType = sessionStorage.getItem("type");
  return (
    <>
      {userType === "vendor" && <DeviceList />}
      {userType === "consumer" && <ConsumerDeviceList />}
    </>
  );
}

export default Devices;
