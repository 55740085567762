import React from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import './Profile.css'
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '../../helpers/FormHelpers';


function ContactInformation() {
  const {register, formState: {errors}} = useFormContext()
  return (
    <div>
      <div className="flex-container">
        <div className="m-3 mt-3 w-75 input-field">
          <label htmlFor="exampleInputEmail1" className="form-label">
            City
          </label>
          <input type="text" {...register('city', {required: "City is required"})} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
          {errors.city && <ErrorMessage message={errors.city.message} />}
        </div>
        <div className="m-3 mt-3 w-75 input-field">
          <label htmlFor="exampleInputEmail1" className="form-label">
            Street
          </label>
          <input type="text" {...register('street', {required: "Street is required"})} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
          {errors.street && <ErrorMessage message={errors.street.message} />}
        </div>
        <div className="m-3 mt-3 w-75 input-field">
          <label htmlFor="exampleInputEmail1" className="form-label">
            Postal Code
          </label>
          <input type="text" {...register('postal_code', {required: "Postal Code is required"})} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
          {errors.postal_code && <ErrorMessage message={errors.postal_code.message} />}
        </div>
      </div>
      <hr />
      <div className="flex-container">
        <div className="m-3 mt-3 w-75 input-field">
          <label htmlFor="exampleInputEmail1" className="form-label">
            Email Address
          </label>
          <input type="email" {...register('email', {required: "Email Address is required"})} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
          {errors.email && <ErrorMessage message={errors.email.message} />}
        </div>
        <div className="m-3 mb-4 w-75 input-field">
          <label htmlFor="exampleInputEmail1" className="form-label">
            Phone Number
          </label>
          <input type="tel" {...register('phone_number', {required: "Phone Number is required"})} className="form-control" />
          {errors.phone_number && <ErrorMessage message={errors.phone_number.message} />}
        </div>
      </div>
      <hr />
      <div className='m-3 mt-3 input-field'>Social Media</div>
      <div className='flex-container'>
      <div className="m-3 mt-3 w-75 input-field">
        <label htmlFor="exampleInputEmail1" className="form-label">
          Facebook
        </label>
        <input type="text" {...register('facebook', {required: false})} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
        {errors.facebook && <ErrorMessage message={errors.facebook.message} />}
      </div>
      <div className="m-3 mt-3 w-75 input-field">
        <label htmlFor="exampleInputEmail1" className="form-label">
          Instagram
        </label>
        <input type="text" {...register('instagram', {required: false})} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
        {errors.instagram && <ErrorMessage message={errors.instagram.message} />}
      </div>
      </div>
      <div className='flex-container'>
      <div className="m-3 mt-3 w-75 input-field">
        <label htmlFor="exampleInputEmail1" className="form-label">
          Linkedin
        </label>
        <input type="text" {...register('linkedin', {required: false })} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
        {errors.linkedin && <ErrorMessage message={errors.linkedin.message} />}
      </div>
      </div>
      <br />
    </div>
  )
}

export default ContactInformation