import React from "react";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { FaPlusCircle } from "react-icons/fa";
import { RiCoupon3Line } from "react-icons/ri";
import ConsumerCouponAdd from "./ConsumerCouponAdd";

const ConsumerCouponList = (props) => {
  const assignedCoupons = JSON.parse(sessionStorage.getItem("assigned_coupons"));
  return (
    <div>
      <div className="text-base text-black m-3 font-weight-bold">
        <RiCoupon3Line style={{ marginBottom: "5px", fontWeight: "bold" }} />{" "}
        <label to="/dashboard" style={{ fontWeight: "bold" }}>
          Coupons
        </label>
      </div>
      <div className="m-4 rounded " style={{ backgroundColor: "#F6FFFf", width: "75%" }}>
        <div>
            <ConsumerCouponAdd/>
          <Table bordered hover size="lg">
            <thead>
              <tr>
                <th>Code</th>
                <th>Descriptions</th>
                <th>Use Coupon</th>
              </tr>
            </thead>
            <tbody>
              {assignedCoupons &&
                assignedCoupons.map((record) => (
                  <tr key={record.id}>
                    <td>{record.code}</td>
                    <td>{record.description}</td>
                    {!record.default && (
                      <td>
                        <Button size="sm" style={{}} variant="success" onClick={() => props.setDefaultCoupon(record.code)}>
                          Switch
                        </Button>
                      </td>
                    )}
                    {record.default && (
                      <td>
                        <Button size="sm" style={{ }} variant="dark">
                          Active
                        </Button>
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </Table>
          <div className="col-sm-offset-2 col-sm-4">
            <h5 className="modal-title" id="">
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsumerCouponList;
