import React from 'react'

function DataDeletion() {
  return (
    <div>
        <h1>Data Deletion Instruction</h1>
        <p>Dr Fever is just a web base application and we do not save your personal data in our server. According to the Facebook Platform rules, we have to provide User Data Deletion Callback URL or Data Deletion Instructions URL. If you want to delete your activities for the FinMonster, following these instructions:</p>
        <ol>
            <li>Go to Your Facebook Account's Setting & Privacy. Click " Setting ".</li>
            <li>Then, go to " Apps and Websites" and you will see all of your Apps activities.</li>
            <li>Select the option box for FinMonster Production</li>
            <li>Click " Remove" button.</li>
        </ol>
    </div>
  )
}

export default DataDeletion