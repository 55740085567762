import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { BiDevices } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
let BASE_URL = "https://production.drfever.co/api";
function DeviceList() {
  const [serialNumber, setSerialNumber] = useState("");
  const [assignedDevices, setAssignedDevices] = useState([]);
  console.log(assignedDevices);
  const navigate = useNavigate();
  useEffect(() => {
    setAssignedDevices(JSON.parse(sessionStorage.getItem("assigned_devices")));
  }, []);

  const setDeviceData = (data, from_consumer_or_vendor) => {
    sessionStorage.setItem("deviceDefaultTransaction" ,JSON.stringify(data.device_default_transaction));
    sessionStorage.setItem("deviceTransactions", JSON.stringify(data.device_transactions));
    console.log(data.assigned_coupons);


  };
  const onVerify = (serial_number, from_consumer_or_vendor, consumer_record_only) => {
    if (!serial_number) {
      serial_number = serialNumber;
    }

    if (from_consumer_or_vendor) {
      sessionStorage.setItem("serialNumber", serial_number);
    }

    const url = `${BASE_URL}/device/${serial_number}/`;
    axios
      .get(url)
      .then((res) => {
        let response_data = res.data;
        if (response_data.type === "device") {
          if (from_consumer_or_vendor) {
            setDeviceData(response_data.data);
            if (consumer_record_only) {
            } else {
              if (response_data.account_setup) {
                sessionStorage.setItem("consumerSetupDeviceReading", JSON.stringify(response_data.account_setup.device_reading));
                sessionStorage.setItem("consumerSetupDeviceReadingKeys", JSON.stringify(response_data.account_setup.device_reading_keys));
                sessionStorage.setItem("consumerSetupAdditionalDeviceReading", JSON.stringify(response_data.account_setup.additional_device_reading));
                sessionStorage.setItem("consumerSetupAdditionalDeviceReadingKeys", JSON.stringify(response_data.account_setup.additional_device_reading_keys));
              }
              setSerialNumber((prevSerialNumber) => {
                return "";
              });
            }
          } else {
            alert("Search not found.");
          }
        }
      })
      .catch((e) => {
        alert("Search not found.");
        console.log("e", e);
      });
  };
  console.log(sessionStorage.getItem("serialNumber"));
  return (
    <div>
      <div className="text-base text-black m-3 font-weight-bold">
        <BiDevices style={{ marginBottom: "5px", fontWeight: "bold" }} />{" "}
        <label to="/dashboard" style={{ fontWeight: "bold" }}>
          Devices
        </label>
      </div>
      <div className="m-4 rounded " style={{ backgroundColor: "#F6FFFf", width: "100%" }} >
        <Table responsive hover size="lg">
          <thead>
            <tr>
              <th>Serial</th>
              <th>Consumer</th>
              <th>Recommendations</th>
            </tr>
          </thead>
          <tbody>
            {assignedDevices.map((assignedDevice) => (
              <tr key={assignedDevice.api_key}>
                <td>{assignedDevice.serial_number}</td>
                <td>
                  <a href="#" onClick={() => onVerify(assignedDevice.serial_number, true, true)}>
                    {assignedDevice.owner_email}
                  </a>
                </td>
                <td>
                  <Button
                    className="btn-sm"
                    variant="primary"
                    onClick={() => {
                      onVerify(assignedDevice.serial_number, true);
                      setTimeout(() =>navigate("/recommendation"), 1000 )
                      
                    }}
                  >
                    Show
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default DeviceList;
