import React from "react";
import ConsumerSymptoms from "../components/DeviceList/ConsumerSymptoms";
import VendorRecomend from "../components/DeviceList/VendorRecomend";

function Recommendations() {
  const userType = sessionStorage.getItem("type")
  return (
    <>
      {userType === "vendor" && (<VendorRecomend />)}
      {userType === "consumer" &&(<ConsumerSymptoms />)}
    </>
  );
}

export default Recommendations;
