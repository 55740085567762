import React from "react";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

const BusinessSetupDeviceList = (props) => {
  return (
    <div>
      <Container>
        <Row>
          <b>
            <a
              href="#showDeviceList"
              id="showDeviceList"
              onClick={(e) => props.handleCollapseState(e)}
            >
              Device Reading {props.showDeviceList}
            </a>
          </b>
          <br />
          <br />
        </Row>
        {props.showDeviceList && (
          <Row>
            <span>
              <Table striped bordered hover>
                <thead>
                  <tr>
                    <th>Label #</th>
                    <th>Default</th>
                    <th>Custom</th>
                  </tr>
                </thead>
                <tbody>
                  <tr key="device_list_title">
                    <td>1</td>
                    <td>Title</td>
                    <td>
                      <input
                        type="text"
                        id="title"
                        defaultValue={props.consumerSetupDeviceList.title}
                        onChange={(e) => props.deviceListHandleChange(e)}
                        className="email-input"
                        placeholder="Title Custom Label"
                      />
                    </td>
                  </tr>

                  <tr key="device_list_serial">
                    <td>1</td>
                    <td>Serial</td>
                    <td>
                      <input
                        type="text"
                        id="serial"
                        defaultValue={props.consumerSetupDeviceList.serial}
                        onChange={(e) => props.deviceListHandleChange(e)}
                        className="email-input"
                        placeholder="Serial Custom Label"
                      />
                    </td>
                  </tr>

                  <tr key="device_list_others">
                    <td>2</td>
                    <td>Others</td>
                    <td>
                      <input
                        type="text"
                        id="others"
                        defaultValue={props.consumerSetupDeviceList.others}
                        onChange={(e) => props.deviceListHandleChange(e)}
                        className="email-input"
                        placeholder="Others Custom Label"
                      />
                      <br />
                      <label>
                        <input
                          type="checkbox"
                          id="others_checked"
                          name="others_checked"
                          value="others_checked"
                          checked={
                            props.consumerSetupDeviceList["others_checked"]
                          }
                          onChange={(e) => props.deviceListHandleChange(e)}
                        />
                        Hide
                      </label>
                    </td>
                  </tr>

                  <tr key="device_list_vendor">
                    <td>3</td>
                    <td>Vendor</td>
                    <td>
                      <input
                        type="text"
                        id="vendor"
                        defaultValue={props.consumerSetupDeviceList.vendor}
                        onChange={(e) => props.deviceListHandleChange(e)}
                        className="email-input"
                        placeholder="Vendor Custom Label"
                      />
                      <br />
                      <label>
                        <input
                          type="checkbox"
                          id="vendor_checked"
                          name="vendor_checked"
                          value="vendor_checked"
                          checked={
                            props.consumerSetupDeviceList["vendor_checked"]
                          }
                          onChange={(e) => props.deviceListHandleChange(e)}
                        />
                        Hide
                      </label>
                    </td>
                  </tr>

                  <tr key="device_list_symptoms">
                    <td>4</td>
                    <td>Symptoms</td>
                    <td>
                      <input
                        type="text"
                        id="symptoms"
                        defaultValue={props.consumerSetupDeviceList.symptoms}
                        onChange={(e) => props.deviceListHandleChange(e)}
                        className="email-input"
                        placeholder="Symptoms Custom Label"
                      />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </span>
          </Row>
        )}
      </Container>
    </div>
  );
};

export default BusinessSetupDeviceList;
