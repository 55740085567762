import axios from "axios";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";

let BASE_URL = "https://production.drfever.co/api";

const Password = (props) => {
  const [PasswordModal, setPasswordModal] = useState(false);
  const [passwordError, setPasswordErr] = useState("");
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(false)
  const [errorMsg, setErrorMsg] = useState()
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [passwordInput, setPasswordInput] = useState({
    password: "",
    confirmPassword: "",
  });
  const handlePasswordChange = (evnt) => {
    const passwordInputValue = evnt.target.value.trim();
    const passwordInputFieldName = evnt.target.name;
    const NewPasswordInput = { ...passwordInput, [passwordInputFieldName]: passwordInputValue };
    setPasswordInput(NewPasswordInput);
  };
  const handleValidation = (evnt) => {
    const passwordInputValue = evnt.target.value.trim();
    const passwordInputFieldName = evnt.target.name;
    //for password
    if (passwordInputFieldName === "password") {
      const uppercaseRegExp = /(?=.*?[A-Z])/;
      const lowercaseRegExp = /(?=.*?[a-z])/;
      const digitsRegExp = /(?=.*?[0-9])/;
      const specialCharRegExp = /(?=.*?[#?!@$%^&*-])/;
      const minLengthRegExp = /.{8,}/;
      const passwordLength = passwordInputValue.length;
      const uppercasePassword = uppercaseRegExp.test(passwordInputValue);
      const lowercasePassword = lowercaseRegExp.test(passwordInputValue);
      const digitsPassword = digitsRegExp.test(passwordInputValue);
      const specialCharPassword = specialCharRegExp.test(passwordInputValue);
      const minLengthPassword = minLengthRegExp.test(passwordInputValue);
      let errMsg = "";
      if (passwordLength === 0) {
        errMsg = "Password is empty";
      } else if (!uppercasePassword) {
        errMsg = "At least one Uppercase";
      } else if (!lowercasePassword) {
        errMsg = "At least one Lowercase";
      } else if (!digitsPassword) {
        errMsg = "At least one digit";
      } else if (!specialCharPassword) {
        errMsg = "At least one Special Characters";
      } else if (!minLengthPassword) {
        errMsg = "At least minumum 8 characters";
      } else {
        errMsg = "";
      }
      setPasswordErr(errMsg);
    }
    // for confirm password
    if (passwordInputFieldName === "confirmPassword" || (passwordInputFieldName === "password" && passwordInput.confirmPassword.length > 0)) {
      if (passwordInput.confirmPassword !== passwordInput.password) {
        setConfirmPasswordError("Confirm password is not matched");
      } else {
        setConfirmPasswordError("");
      }
    }
  };

  const ChangePassword = () => {
    let params = {
      old_password: oldPassword,
      password: passwordInput.password
    }
    let id = sessionStorage.getItem("id")
    axios.put(`${BASE_URL}/password-reset/${id}/`, params).then((res) => {
      console.log(res.data);
      setSuccess(true)
      setError(false)
      setTimeout(() => {setPasswordModal(false)
      setSuccess(false)}, 2000)
      setPasswordInput({
        password: "",
        confirmPassword: "",
      })
      setOldPassword("")
    }).catch((e) => {
      console.log(e.response.data.old_password[0])
      setErrorMsg(e.response.data.old_password[0])
      setError(true)
    })
  }
  return (
    <div>
      <div>
        <div className="col-sm-1 col-sm-offset-5">
          <Button
            size="sm"
            variant="primary"
            style={{ width: "180px", height: "40px" }}
            onClick={(e) => {
              setPasswordModal(!PasswordModal);
            }}
          >
            Change Password
          </Button>
        </div>
      </div>
      <div></div>
      <Modal show={PasswordModal} size="xs" onHide={() => setPasswordModal(!PasswordModal)} aria-labelledby="contained-modal-title-vcenter" centered>
        <div className="modal-header" style={{ fontWeight: "bold" }}>
          Change Password
        </div>
        <Modal.Body>
        {success && <div className="alert alert-success" role="alert">
Password Updated Successfully!
        </div>}
        {error && <div className="alert alert-danger" role="alert">
          {errorMsg}
        </div>}
          <div className="m-3 mt-3 w-75 input-field">
            <label htmlFor="exampleInputEmail1" className="form-label">
              Old Password
            </label>
            <input type="password" placeholder="Old Password" value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} className="form-control" />
          </div>
          <div className="m-3 mt-3 w-75 input-field">
            <label htmlFor="exampleInputEmail1" className="form-label">
              New Password
            </label>
            <input type='password' value={passwordInput.password} onChange={handlePasswordChange} onKeyUp={handleValidation} name="password" placeholder="New Password" className="form-control" />
            <p className="text-danger">{passwordError}</p>
          </div>
          <div className="m-3 mt-3 w-75 input-field">
            <label htmlFor="exampleInputEmail1" className="form-label">
              Confirm New Password
            </label>
            <input type="password" value={passwordInput.confirmPassword} onChange={handlePasswordChange} onKeyUp={handleValidation} name="confirmPassword" placeholder="Confirm Password" className="form-control" />
            <p className="text-danger">{confirmPasswordError}</p>
          </div>
          <ul>
            <li>Password must contain more than 8 Characters</li>
            <li>Password must have numbers</li>
            <li>Password must have uppercase letter, special character</li>
          </ul>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="ghost" onClick={() => setPasswordModal(!PasswordModal)}>
            Close
          </Button>
          <Button variant="primary" style={{ width: 150 }} onClick={() => ChangePassword()}>
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Password;
