import axios from "axios";
import React, { useState } from "react";
import { Container, Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import { AiOutlineClose } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import "../App.css";

const Login = (props) => {
  const [LoginInput, setLoginInput] = useState("");
  const [LoginStatus, setLogInStatus] = useState("Login");
  const [otpModal, setOtpModal] = useState(false);
  const [otpInput, setOtpInput] = useState("");

  let BASE_URL = "https://production.drfever.co/api";
  let BASE_DOMAIN = "https://production.drfever.co";

  const navigate = useNavigate()

  const onLoginSubmit = (verify_otp) => {
    let url = `${BASE_URL}/login/${LoginInput}/`;
    if (verify_otp) {
      url = `${BASE_URL}/login/${LoginInput}/${otpInput}/`;
    }

    setLogInStatus("Submitting");
    axios
      .get(url)
      .then((res) => {
        if (res.data !== "verify_otp") {
          let response_data = res.data;
          console.log(response_data);
          setLogInStatus("Submit");
          setOtpModal(false);
          if(res.data.type === "vendor"){
            sessionStorage.setItem("sideBarShow", false)
          sessionStorage.setItem('isAuthenticated', true)
          sessionStorage.setItem('first_name', response_data.first_name)
          sessionStorage.setItem('last_name', response_data.last_name)
          sessionStorage.setItem('email', response_data.email)
          sessionStorage.setItem('phone_number', response_data.phone_number)
          sessionStorage.setItem('type', response_data.type)
          sessionStorage.setItem('bypass_otp', response_data.bypass_otp)
          sessionStorage.setItem('id', response_data.id)
          sessionStorage.setItem('assigned_coupons', JSON.stringify(response_data.assigned_coupons))
          sessionStorage.setItem('loginInput', LoginInput)
          sessionStorage.setItem('assigned_devices', JSON.stringify(response_data.assigned_devices))
          sessionStorage.setItem('assigned_consumers', JSON.stringify(response_data.assigned_consumers))
          } else if(res.data.type === "consumer") {
            sessionStorage.setItem("sideBarShow", false)
            sessionStorage.setItem('isAuthenticated', true)
          sessionStorage.setItem('first_name', response_data.first_name)
          sessionStorage.setItem('last_name', response_data.last_name)
          sessionStorage.setItem('email', response_data.email)
          sessionStorage.setItem('phone_number', response_data.phone_number)
          sessionStorage.setItem('type', response_data.type)
          sessionStorage.setItem('bypass_otp', response_data.bypass_otp)
          sessionStorage.setItem('id', response_data.id)
          sessionStorage.setItem('coupon', response_data.coupon)
          sessionStorage.setItem('disable_update_bypass_otp', response_data.disable_update_bypass_otp)
          sessionStorage.setItem('assigned_coupons', JSON.stringify(response_data.assigned_coupons))
          sessionStorage.setItem('loginInput', LoginInput)
          sessionStorage.setItem('assigned_devices', JSON.stringify(response_data.assigned_devices))
          sessionStorage.setItem('device_reading', JSON.stringify(response_data.account_setup.device_reading))
          sessionStorage.setItem('additional_device_reading', JSON.stringify(response_data.account_setup.additional_device_reading))
          sessionStorage.setItem('device_reading_keys', JSON.stringify(response_data.account_setup.device_reading_keys))
          sessionStorage.setItem('symptoms', JSON.stringify(response_data.account_setup.symptoms))
          sessionStorage.setItem('device_list', JSON.stringify(response_data.account_setup.device_list))
          sessionStorage.setItem('additional_device_reading_keys', JSON.stringify(response_data.account_setup.additional_device_reading_keys))
          sessionStorage.setItem('default_consumer_device', JSON.stringify(response_data.default_consumer_device))
          }
          navigate('/dashboard')
          window.location.reload()
           props.setLoginModal(false);
        } else {
          setLogInStatus("Submit");
          setOtpInput("");
          setOtpModal(true);
        }
      })
      .catch((e) => {
        setLogInStatus("Submit");
        if (e.response.status === 400) {
          alert(e.response.data);
          if (e.response.data === "OTP is expired.") {
            setOtpModal(false);
          }
        } else {
          if (verify_otp) {
            alert("OTP is incorrect.");
          }
        }
        console.log("e", e);
      });
  };

  const switchHandle = () => {
    props.setLoginModal(!props.LoginModal);
    props.setRegisterModal(!props.registerModal);
  };
  return (
    <div>
      <li>
        <a
          href="#login"
          onClick={() => {
            props.openLoginModal();
          }}
        >
          Login
        </a>
      </li>
      <Modal size="lg" centered show={props.LoginModal} onHide={() => props.setRegisterModal(!props.LoginModal)} aria-labelledby="contained-modal-title-vcenter">
        <Modal.Body className="container-fluid">
          <div className="exit" onClick={() => props.setLoginModal(!props.LoginModal)}>
            <AiOutlineClose />
          </div>
          <Container style={{ textAlign: "right", display: "flex", height: "30rem" }}>
            <img alt="register" src="./registerPhoto.jpg" width="50%" height="50%" className="img-fluid rounded registerImg"></img>
            <Container className="text-center text-fluid fluid" style={{ marginTop: "4rem" }}>
              <div className="col-sm-12">
                <h5>Sign In</h5>
                <br />
                <br />
                <br />
                <br />
                <br />
              </div>
              <div className="col-12">
                <input type="text" className="animationInput animate" placeholder="Enter Phone Number or Email" onChange={(e) => setLoginInput(e.target.value)} value={LoginInput} />
                <br />
                <br />
                <div>
                  Don't have an account? &nbsp;
                  <a href="#" onClick={() => switchHandle()}>
                    Register
                  </a>{" "}
                </div>
              </div>
              <br />
            </Container>
            <div className="loginButton">
              <button className="btn btn-light" type="button" variant="success" style={{ width: 150 }} disabled={LoginStatus === "Submitting"} onClick={() => onLoginSubmit()}>
                {LoginStatus}
              </button>
            </div>
          </Container>
        </Modal.Body>
      </Modal>

      {/* OTP MODAL */}
      <Modal show={otpModal} onHide={() => setOtpModal(!otpModal)} aria-labelledby="contained-modal-title-vcenter" centered>
        <div className="modal-header">
          <h5 className="modal-title" id="">
            One Time Password (Check your Email/Mobile)
          </h5>
        </div>
        <Modal.Body>
          <div className="col-12">
            <input type="text" className="col-12 email-input" placeholder="Enter OTP" onChange={(e) => setOtpInput(e.target.value)} value={otpInput} />
            <br />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setOtpModal(!otpModal)}>
            Close
          </Button>
          <Button variant="success" onClick={() => onLoginSubmit(true)} style={{ width: 150 }}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Login;
