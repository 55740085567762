import React from 'react';

const Detail = () => {
    return (
        <>
            <div>
                <span>Details Page</span>
            </div>
            
        </>
    )
}

export default Detail;