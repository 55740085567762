import React from "react";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";

const VendorModal = (props) => {
  return (
    <div>
      <Modal
        show={props.vendorModal}
        onHide={() => props.setVendorModal(!props.vendorModal)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal-header">
          <div className="col-sm-6">
            <h5 className="modal-title" id="">
              Welcome {props.vendorFirstName} {props.vendorLastName}!
            </h5>
          </div>
          {props.vendorCoupon && (
            <div className="col-sm-offset-2 col-sm-4">
              <h5 className="modal-title" id="">
                <a href="#">Setup Business</a>
              </h5>
            </div>
          )}
        </div>
        <Modal.Body>
          <div className="row">
            <div className="col-sm-4">
              <button
                className="btn-info btn-md"
                onClick={() => props.setDeviceListModal(!props.deviceListModal)}
              >
                Assigned &nbsp; Devices
              </button>
            </div>
            <div className="col-sm-4">
              <button
                className="btn-info"
                onClick={() => props.setCouponListModal(!props.couponListModal)}
              >
                Assigned Coupons
              </button>
            </div>
            <div className="col-sm-4">
              <button
                className="btn-info"
                onClick={() =>
                  props.setConsumerListModal(!props.consumerListModal)
                }
              >
                Assigned Consumers
              </button>
            </div>
          </div>

          <br />
          <hr />
          <div className="col-12">
            <span className="col-12">Email Address</span>
            <input
              type="text"
              className="col-12 email-input"
              onChange={(e) => props.setVendorEmail(e.target.value)}
              value={props.vendorEmail}
              placeholder="Email Address"
            />
          </div>

          <br />

          <div className="col-12">
            <span className="col-12">First Name</span>
            <input
              type="text"
              className="col-12 email-input"
              onChange={(e) => props.setVendorFirstName(e.target.value)}
              value={props.vendorFirstName}
              placeholder="First Name"
            />
          </div>

          <br />

          <div className="col-12">
            <span className="col-12">Last Name</span>
            <input
              type="text"
              className="col-12 email-input"
              onChange={(e) => props.setVendorLastName(e.target.value)}
              value={props.vendorLastName}
              placeholder="Last Name"
            />
          </div>

          <br />

          <div className="col-12">
            <span className="col-12">Phone Number</span>
            <input
              type="text"
              className="col-12 email-input"
              onChange={(e) => props.setVendorPhoneNumber(e.target.value)}
              value={props.vendorPhoneNumber}
              placeholder="Phone Number"
            />
          </div>

          <br />

          <div className="col-12">
            <span className="col-12">Bypass OTP</span>
            <input
              type="text"
              className="col-12 email-input"
              onChange={(e) => props.setVendorBypassOTP(e.target.value)}
              value={props.vendorBypassOTP}
              placeholder="Bypass OTP"
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => props.setVendorModal(!props.vendorModal)}
          >
            Close
          </Button>
          <Button
            variant="danger"
            onClick={() => props.onSendUpdateVendorData()}
            style={{ width: 150 }}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default VendorModal;
