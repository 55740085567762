import axios from "axios";
import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { FaPlusCircle } from "react-icons/fa";

let BASE_URL = "https://production.drfever.co/api";

function CouponAddModal() {
  const [currentVendorCoupon, setCurrentVendorCoupon] = useState({});
  const [vendorCouponAddModal, setVendorCouponAddModal] = useState(false);
  const [autoApproval, setAutoApproval] = useState(false);

  const { register, handleSubmit, getValues } = useForm({mode: "onSubmit"});
  const onSendUpdateCoupon = () => {
    console.log("clicked");
    const couponData = {
      "code": getValues("code"),
      "description": getValues("description"),
      "no_of_coupons": parseInt(getValues("no_of_coupons")),
      "auto_approval": autoApproval,
      "vendor": sessionStorage.getItem("id")
    };
    let url = `${BASE_URL}/coupon/`;
    axios
      .post(url, couponData)
      .then((res) => {
        let assigned_coupons = sessionStorage.getItem()
        // sessionStorage.setItem("assigned_coupons", JSON.stringify(res.data.data));
        setVendorCouponAddModal(!vendorCouponAddModal);
      })
      .catch((e) => {
        if (e.response.status === 400) {
          alert(e.response.data);
        } else {
          alert("Something went wrong.");
        }
      });
  };
  return (
      <div>
        
        {" "}
        <div className="modal-header">
          <div className="col-sm-1 col-sm-offset-5">
            <Button
              size="sm"
              variant="primary"
              style={{ width: "180px", height: "40px" }}
              onClick={(e) => {
                setCurrentVendorCoupon({});
                setVendorCouponAddModal(true);
              }}
            >
              <FaPlusCircle /> Add New Coupon
            </Button>
          </div>
        </div>
        <div>
          <form name="form1" onSubmit={handleSubmit(onSendUpdateCoupon)}>
          <Modal show={vendorCouponAddModal} onHide={() => setVendorCouponAddModal(!vendorCouponAddModal)} aria-labelledby="contained-modal-title-vcenter" centered>
            <div className="modal-header">Add/Update Coupon</div>
            <Modal.Body>
              <div className="col-12">
                <span className="col-12">Code</span>
                <input type="text" id="code" className="col-12 form-control" {...register("code", { required: false })} value={currentVendorCoupon.code} placeholder="Coupon Code" />
              </div>
              <br />

              <div className="col-12">
                <span className="col-12">Description</span>
                <input type="text" className="col-12 form-control" id="description" {...register("description", { required: false })} value={currentVendorCoupon.description} placeholder="Coupon Description" />
              </div>
              <br />

              <div className="col-12">
                <span className="col-12">No. of Coupons</span>
                <input type="number" id="no_of_coupons" className="col-12 form-control" {...register("no_of_coupons", { required: false })} value={currentVendorCoupon.no_of_coupons} placeholder="No. of Coupons" />
              </div>
              <br />

              <div className="col-12">
                <input type="checkbox" id="auto_approval" name="auto_approval" value="auto_approval" {...register("approve", { required: false })} onClick={() => setAutoApproval(!autoApproval)} />
                <span className="col-12"> Auto Approval</span>
              </div>
              <br />
            </Modal.Body>
            <form onSubmit = {(e) => console.log(e)}>
            <Modal.Footer>
                <Button variant="ghost" onClick={() => setVendorCouponAddModal(!vendorCouponAddModal)}>
                Close
              </Button>
              <Button variant="primary" type="submit" style={{ width: 150 }}>
                Save Changes
              </Button>
              </Modal.Footer>
              </form>
          </Modal>
          </form>
        </div>
        
      </div>
    
  );
}

export default CouponAddModal;
