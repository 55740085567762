import React from "react";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";

const ConsumerCouponAdd = (props) => {
  return (
    <div>
      <Modal
        show={props.consumerCouponAddModal}
        size="xs"
        onHide={() =>
          props.setConsumerCouponAddModal(!props.consumerCouponAddModal)
        }
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal-header">Add</div>
        <Modal.Body>
          <div className="col-12">
            <span className="col-12">Code</span>
            <input
              type="text"
              id="code"
              className="col-12 email-input"
              onChange={(e) => props.setNewConsumerCoupon(e)}
              value={props.currentConsumerCoupon.code}
              placeholder="Coupon Code"
            />
          </div>
          <br />
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() =>
              props.setConsumerCouponAddModal(!props.consumerCouponAddModal)
            }
          >
            Close
          </Button>
          <Button
            variant="success"
            onClick={() => props.onSendUpdateConsumerCoupon()}
            style={{ width: 150 }}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ConsumerCouponAdd;
