/* eslint-disable no-useless-escape */
import '../App.css'
export const isEmailValid = (e) => {
    const regex = new RegExp(`[a-z0-9]+@(?:(?:[a-zA-Z0-9-]+\.)?[a-zA-Z]+\.)\.[a-z]{2,3}`);
    if (!e) {
        return null;
    }
    return regex.test(e);
};

export function ErrorMessage({ message }) {
    return <label className='errorMsg' >{message}</label>;
}

export function SuccessMsg({ message }) {
    return <label className='successMsg' style={{color: "green"}} >{message}</label>;
}

export function SuccessMessage({message}) {
    return <div class="alert alert-success" role="alert">{message}</div>
}

export function Error({message}) {
    return <div class="alert alert-danger" role="alert">{message}</div>
}

export const isMobileNumber = (e) => {
    if (!e) {
        return true;
    }
    return /([0-9]●?){9,14}[0-9]$/
    .test(e);
};

export const isWebsite = (e) => {
    const regex = new RegExp(/^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?\/?$/gm
    );
    if (!e) {
        return null;
    }
    return regex.test(e);
};