/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Sidebar.css";
import {AiOutlineHome, AiOutlineSchedule} from 'react-icons/ai'
import {RiCoupon3Line} from 'react-icons/ri'
import {BiDevices} from 'react-icons/bi'
import {MdNavigateBefore, MdProductionQuantityLimits} from 'react-icons/md'
import {IoExitOutline, IoPeople} from 'react-icons/io5'
import {TbBrandGoogleAnalytics} from 'react-icons/tb'
import {FaRegCalendarAlt} from 'react-icons/fa'

function Sidebar(props) {
    const [sideBar, setSideBar] = useState(false)
    const [show, setShow] = useState(false)
    const linkColor = document.querySelectorAll('.nav_link')
    const isAuthenticated = sessionStorage.getItem('isAuthenticated')
    let status = sessionStorage.getItem("showSideBar")


function colorLink(){
if(linkColor){
linkColor.forEach(l=> l.classList.remove('active'))
this.classList.add('active')
}
}
const userType = sessionStorage.getItem("type")
linkColor.forEach(l=> l.addEventListener('click', colorLink))

  return (
    <>

    {isAuthenticated && (<div className={props.show === true ? "col-auto min-vh-100 appear animation": "col-auto min-vh-100 disappear animation"}  id="nav" style={{padding: "0"}}>
    <div className="l-navbar" id="nav-bar">
      <nav className="nav">
        <div>
          {" "}
          <a href="#" className="nav_logo" onClick={() => setShow(!show)}> <span className="nav_logo-name">Dr.Fever</span> <MdNavigateBefore size={30} className="mt-1 p-pusher" onClick={() => {props.setShow(false)}}/> </a>
          
          <div className="nav_list">
            {" "}
            <a href="/dashboard" className="nav_link" onClick={() => {props.setShow(false)}}>
              {" "}
              <AiOutlineHome/> <span className="nav_name">Dashboard</span>{" "}
            </a>{" "}
            <a href="/coupons" className="nav_link" onClick={() => {props.setShow(false)}}>
              {" "}
             <RiCoupon3Line/><span className="nav_name">Coupons</span>{" "}
            </a>{" "}
            <a href="/devices" className="nav_link" onClick={() => {props.setShow(false)}}>
              {" "}
              <BiDevices/> <span className="nav_name">Devices</span>{" "}
            </a>{" "}
           {userType === "vendor" && <a href="/consumers" className="nav_link" onClick={() => {props.setShow(false)}} >
              {" "}
              <IoPeople/> <span className="nav_name">Consumers</span>{" "}
            </a>}
            <a href="#" className="nav_link" onClick={() => {props.setShow(false)}}>
              {" "}
            <MdProductionQuantityLimits/><span className="nav_name">Products</span>{" "}
            </a>{" "}
            <a href="#" className="nav_link" onClick={() => {props.setShow(false)}}>
              {" "}
            <AiOutlineSchedule/><span className="nav_name">Schedule</span>{" "}
            </a>{" "}
            <a href="#" className="nav_link" onClick={() => {props.setShow(false)}}>
              {" "}
            <FaRegCalendarAlt/><span className="nav_name">Calender</span>{" "}
            </a>{" "}
            <a href="#" className="nav_link" onClick={() => {props.setShow(false)}}>
              {" "}
            <TbBrandGoogleAnalytics/><span className="nav_name">Analytics</span>{" "}
            </a>{" "}
            
          </div>
        </div>{" "}
        {props.show && <a href="/" className="nav_link" onClick={() => sessionStorage.clear()}><IoExitOutline/> <span className="nav_name">SignOut</span> </a>}
      </nav>
    </div>
    </div>)}
    </>
    
  );
}

export default Sidebar;
