import React from "react";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";

const ConsumerDeviceList = (props) => {
  return (
    <div>
      <Modal
        show={props.deviceConsumerListModal}
        onHide={() =>
          props.setDeviceConsumerListModal(!props.deviceConsumerListModal)
        }
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal-header">
          <div className="col-sm-12">
            <h5 className="modal-title" id="">
              {props.consumerSetupDeviceList.title}
            </h5>
          </div>
        </div>
        <Modal.Body>
          <Table striped bordered hover size="lg">
            <thead>
              <tr>
                <th>{props.consumerSetupDeviceList.serial}</th>
                {!props.consumerSetupDeviceList.others_checked && (
                  <th>{props.consumerSetupDeviceList.others}</th>
                )}

                {!props.consumerSetupDeviceList.vendor_checked && (
                  <th>{props.consumerSetupDeviceList.vendor}</th>
                )}

                <th>{props.consumerSetupDeviceList.symptoms}</th>
              </tr>
            </thead>
            <tbody>
              {props.assignedDevices.map((assignedDevice) => (
                <tr key={assignedDevice.api_key}>
                  {!assignedDevice.default_consumer_device && (
                    <td>{assignedDevice.serial_number}</td>
                  )}
                  {assignedDevice.default_consumer_device && <td>Default</td>}

                  {!props.consumerSetupDeviceList.others_checked && (
                    <td>
                      {assignedDevice.default_consumer_device && (
                        <span>Default</span>
                      )}
                    </td>
                  )}

                  {!props.consumerSetupDeviceList.vendor_checked && (
                    <td>{assignedDevice.vendor_email}</td>
                  )}

                  {assignedDevice.has_transactions && (
                    <td>
                      <Button
                        className="btn-sm"
                        variant="primary"
                        onClick={() =>
                          props.onVerify(assignedDevice.serial_number, true)
                        }
                      >
                        Show
                      </Button>
                    </td>
                  )}
                  {!assignedDevice.has_transactions && (
                    <td>
                      <Button className="btn-sm" variant="dark">
                        No Reading
                      </Button>
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() =>
              props.setDeviceConsumerListModal(!props.deviceConsumerListModal)
            }
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ConsumerDeviceList;
