import React, { useEffect, useState } from "react";
import { Button, Pagination, Table } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";
import { FiSettings } from "react-icons/fi";
import { RiCoupon3Line } from "react-icons/ri";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Coupon.css";
import CouponAddModal from "./CouponAddModal";
import { useNavigate } from "react-router-dom";
import CouponEditModal from "./CouponEdit";

function CouponList() {
  const [assignedCoupons, setAssignedCoupons] = useState([]);
  const [currentVendorCoupon, setCurrentVendorCoupon] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [firstRow, setFirstRow] = useState(0);
  const [lastRow, setLastRow] = useState(9);
  const [rerenderPage, setRerenderPage] = useState(true);

  const navigate = useNavigate();

  useEffect(() => {
    setAssignedCoupons(JSON.parse(sessionStorage.getItem("assigned_coupons")));
  }, []);

  const handleCouponSetup = (id, code) => {
    navigate("/coupon-setup", { state: { id: id, code: code } });
  };

  const pageRender = (page) => {
    setCurrentPage(page);
    setFirstRow(0 + 10 * (currentPage - 1));
    setLastRow(9 + 10 * (currentPage - 1));
    console.log(firstRow + " " + lastRow);
  };
  useEffect(() => {
    setRerenderPage(false);
    setRerenderPage(true);
  }, [lastRow]);

  return (
    <div>
      <div className="text-base text-black m-3 font-weight-bold">
        <RiCoupon3Line style={{ marginBottom: "5px", fontWeight: "bold" }} />{" "}
        <label to="/dashboard" style={{ fontWeight: "bold" }}>
          Coupons
        </label>
      </div>
      <div className="m-4 rounded " style={{ backgroundColor: "#F6FFFf", width: "90%" }}>
        <CouponAddModal />
        <Table bordered hover size="lg" responsive border={false} variant="ghost">
          <thead style={{ textAlign: "center" }}>
            <tr>
              <th>Code</th>
              <th>Descriptions</th>
              <th>No. of Coupons</th>
              <th>Available Coupons</th>
              <th>Auto Approval</th>
              <th>Edit</th>
              <th>Settings</th>
            </tr>
          </thead>
          <tbody style={{ textAlign: "center" }}>
            {assignedCoupons &&
              assignedCoupons.map((record) => (
                <tr key={record.id}>
                  <td>{record.code}</td>
                  <td>{record.description}</td>
                  <td>{record.no_of_coupons}</td>
                  <td>{record.unused_coupons}</td>
                  <td>{record.auto_approval_display}</td>
                  <td className="center-button">
                    <CouponEditModal id={record.id} code = {record.code} description={record.description} no_of_coupons={record.no_of_coupons} auto_approval = {record.auto_approval_display} />
                  </td>
                  <td className="center-button">
                    <Button
                      size="sm"
                      variant="ghost"
                      style={{ width: "60px" }}
                      onClick={(e) => {
                        handleCouponSetup(record.id, record.code);
                      }}
                    >
                      <FiSettings />
                    </Button>
                  </td>
                </tr>
              ))}
          </tbody>
        </Table>
      </div>
      <Pagination
        currentPage={currentPage}
        total={assignedCoupons.length}
        limit={10}
        onPageChange={(page) => {
          pageRender(page);
        }}
      />
    </div>
  );
}

export default CouponList;
