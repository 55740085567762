import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "../../helpers/FormHelpers";

function PersonalInformation() {
  const {register, formState: {errors}} = useFormContext()
  return (
    <div>
      <div className="flex-container">
        <div className="m-3 mt-3 w-75 input-field">
          <label htmlFor="exampleInputEmail1" className="form-label">
            First Name
          </label>
          <input type="text" {...register('first_name', {required: "First name is required"})} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
          {errors.first_name && <ErrorMessage message={errors.first_name.message} />}
        </div>
        <div className="m-3 w-75 input-field">
          <label htmlFor="exampleInputEmail1" className="form-label">
            Last Name
          </label>
          <input type="text" {...register('last_name', {required: "Last name is required"})} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
          {errors.last_name && <ErrorMessage message={errors.last_name.message} />}
        </div>
      </div>
      <hr />
      <div className="flex-container">
        <div className="m-3 mt-3 w-75 input-field">
          <label htmlFor="exampleInputEmail1" className="form-label">
            Date of birth
          </label>
          <input type="date" {...register('date', {required: "Date of birth is required"})} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
          {errors.date && <ErrorMessage message={errors.date.message} />}
        </div>
        <div className="m-3 mt-3 w-75 input-field">
        <label htmlFor="exampleInputEmail1" className="form-label">
          OTP
        </label>
        <input type="text" {...register('otp', {required: "OTP is required"})} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
        {errors.otp && <ErrorMessage message={errors.otp.message} />}
      </div>
      </div>
      <br />
    </div>
  );
}

export default PersonalInformation;
