import React from 'react'
import "bootstrap/dist/css/bootstrap.min.css";


function OtherDetails() {
  return (
    <div>
        OtherDetails
    </div>
  )
}

export default OtherDetails