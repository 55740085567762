import React from "react";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";

const VendorConsumerList = (props) => {
  return (
    <div>
      <Modal
        show={props.consumerListModal}
        onHide={() => props.setConsumerListModal(!props.consumerListModal)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal-header">
          <div className="col-sm-6">
            <h5 className="modal-title" id="">
              {props.vendorFirstName} {props.vendorLastName} | Consumer Lists
            </h5>
          </div>
        </div>
        <Modal.Body>
          <Table striped bordered hover size="lg">
            <thead>
              <tr>
                <th>Firstname</th>
                <th>Lastname</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Coupon Used</th>
                <th>Approved</th>
                <th>Editable OTP</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {props.assignedConsumers.map((record) => (
                <tr key={record.id}>
                  <td>{record.first_name}</td>
                  <td>{record.last_name}</td>
                  <td>{record.email}</td>
                  <td>{record.phone_number}</td>
                  <td>{record.coupon_display}</td>
                  <td>
                    <input
                      name="approved"
                      type="checkbox"
                      value="approved"
                      defaultChecked={record.approved}
                      onChange={(e) => props.consumerApproveChanged(record, e)}
                    />
                  </td>
                  <td>
                    <input
                      name="disable_update_bypass_otp"
                      type="checkbox"
                      value="disable_update_bypass_otp"
                      defaultChecked={record.disable_update_bypass_otp}
                      onChange={(e) => props.consumerApproveChanged(record, e)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => props.setConsumerListModal(!props.consumerListModal)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default VendorConsumerList;
