/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CreatableSelect from "react-select/creatable";
import DeviceReading from "../DeviceReading";
import { BiDevices } from "react-icons/bi";

let BASE_URL = "https://production.drfever.co/api";
let BASE_DOMAIN = "https://production.drfever.co";

class VendorRecomend extends React.Component {
  state = {
    transactionID: null,
    description: "",
    transactionSymptoms: [],
  };

  onBlurRecommendation = (e, transactionSymptom) => {
    axios
      .put(
        `${BASE_URL}/transaction_symptoms/${transactionSymptom.transaction}/`,
        {
          id: transactionSymptom.id,
          recommendation: e.target.value,
        }
      )
      .then((res) => {
        // this.readConsumerSymptoms();
      });
  };

  handleRecommendationChange = (e, transactionSymptom) => {
    transactionSymptom.recommendation = e.target.value;
    const recommendation_id = `recommendation_${transactionSymptom.id}`;

    this.setState({
      [recommendation_id]: {
        ...this.state[recommendation_id],
        recommendation: e.target.value,
      },
    });
  };

  handleProductChange = (e, transactionSymptom) => {
    if (e) {
      transactionSymptom.product = e;
    } else {
      transactionSymptom.product = {};
    }

    const product_id = `product_${transactionSymptom.id}`;

    this.setState({
      [product_id]: {
        ...this.state[product_id],
        product: transactionSymptom.product,
      },
    });

    axios
      .put(
        `${BASE_URL}/transaction_symptoms/${transactionSymptom.transaction}/`,
        {
          id: transactionSymptom.id,
          product: transactionSymptom.product,
        }
      )
      .then((res) => {});
  };

  handleTransactionChange = (e) => {
    this.setState({
      ["transactionID"]: e.value,
    });

    this.readConsumerSymptoms(e.value);
    // axios.post(`${BASE_URL}/device/${this.props.serialNumber}/`, {
    //     transactionID: e.value,
    // }).then(res => {
    // })
  };

  addConsumerSymptoms = () => {
    this.setState({
      ["description"]: "",
    });

    axios
      .put(
        `${BASE_URL}/transaction_symptoms/${this.state.transactionID}/`,
        {
          description: this.state.description,
        }
      )
      .then((res) => {
        this.readConsumerSymptoms();
      });
  };

  readConsumerSymptoms = (transactionID) => {
    if (!transactionID) {
      transactionID = this.state.transactionID;
    }

    axios
      .get(`${BASE_URL}/transaction_symptoms/${transactionID}/`)
      .then((res) => {
        this.setState({
          ["transactionSymptoms"]: res.data,
        });

        for (var i in res.data) {
          const recommendation_id = `recommendation_${res.data[i].id}`;
          const product_id = `product_${res.data[i].id}`;
          this.setState({
            [recommendation_id]: res.data[i],
            [product_id]: res.data[i],
          });
        }
      });
  };

  deleteConsumerSymptoms = (transactionSymptomID) => {
    axios
      .delete(
        `${BASE_URL}/transaction_symptoms/${transactionSymptomID}/`
      )
      .then((res) => {
        this.readConsumerSymptoms();
      });
  };

  initiate = () => {
    this.setState({
      ["transactionID"]: sessionStorage.getItem("deviceDefaultTransaction").value,
    });

    this.readConsumerSymptoms(sessionStorage.getItem("deviceDefaultTransaction").value);
  };

  render() {
    return (
      <div>
      <div className="text-base text-black m-3 font-weight-bold">
        <BiDevices style={{ marginBottom: "5px", fontWeight: "bold" }} />{" "}
        <label to="/dashboard" style={{ fontWeight: "bold" }}>
          Devices
        </label>
      </div>
      <div className="m-4 rounded " style={{ backgroundColor: "#F6FFFf", width: "75%" }}>
      <div>
          <div className="modal-header">
            <h5 className="modal-title" id="">
              Welcome {sessionStorage.getItem("first_name")} {sessionStorage.getItem("last_name")}!
            </h5>
            <a
              href="#"  
            >
              Update Vendor Info
            </a>
          </div>
            <Container>
              <Row>
                <Col sm={4} style={{margin: "20px 0px"}}>
                  <div className="col-12">
                    <CreatableSelect
                      placeholder="Device Reading"
                      isSearchable
                      isClearable
                      options={JSON.parse(sessionStorage.getItem("deviceTransactions"))}
                      defaultValue={JSON.parse(sessionStorage.getItem("deviceDefaultTransaction"))}
                      onChange={(e) => this.handleTransactionChange(e)}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Table responsive   hover size="lg">
                    <thead>
                      <tr>
                        <th width="25%">Symptoms</th>
                        <th width="30%">Symptoms Recommendation</th>
                        <th width="35%">Product</th>
                        <th>Image</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.transactionSymptoms.map(
                        (transactionSymptom) => (
                          <tr key={transactionSymptom.id}>
                            <td>{transactionSymptom.description}</td>
                            <td>
                              <input
                                type="text"
                                className="col-12"
                                id={transactionSymptom.id}
                                value={transactionSymptom.recommendation}
                                onBlur={(e) =>
                                  this.onBlurRecommendation(
                                    e,
                                    transactionSymptom
                                  )
                                }
                                onChange={(e) =>
                                  this.handleRecommendationChange(
                                    e,
                                    transactionSymptom
                                  )
                                }
                                placeholder="Symptoms Recommendation"
                              />
                            </td>

                            <td>
                              <CreatableSelect
                                placeholder="Select Product"
                                isSearchable
                                isClearable
                                options={sessionStorage.getItem("productList")}
                                defaultValue={transactionSymptom.product}
                                onChange={(e) =>
                                  this.handleProductChange(
                                    e,
                                    transactionSymptom
                                  )
                                }
                              />
                            </td>
                            <td>
                              {transactionSymptom.product &&
                                transactionSymptom.product.image && (
                                  <Col sm={1}>
                                    <a
                                      href={`${BASE_DOMAIN}/static/products/${transactionSymptom.product.image}`}
                                      target="_blank"
                                    >
                                      <img
                                        style={{ marginTop: "30%" }}
                                        width="70px"
                                        height="40px"
                                        src={`${BASE_DOMAIN}/static/products/${transactionSymptom.product.image}`}
                                      />
                                    </a>
                                  </Col>
                                )}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row>
                <Col sm={4}>
                  <DeviceReading
                    userType={sessionStorage.getItem("type")}
                    deviceDefaultTransaction={
                      JSON.parse(sessionStorage.getItem("deviceDefaultTransaction"))
                    }
                    consumerSetupAdditionalDeviceReadingKeys={
                      JSON.parse(sessionStorage.getItem("consumerSetupAdditionalDeviceReadingKeys"))
                    }
                    consumerSetupAdditionalDeviceReading={
                     JSON.parse(sessionStorage.getItem("consumerSetupAdditionalDeviceReading"))
                    }
                    consumerSetupDeviceReadingKeys={
                      JSON.parse(sessionStorage.getItem("consumerSetupDeviceReadingKeys"))
                    }
                    consumerSetupDeviceReading={
                      JSON.parse(sessionStorage.getItem("consumerSetupDeviceReading"))
                    }
                  />
                </Col>
              </Row>
            </Container>
      </div>
      </div>
    </div>
    );
  }
}

export default VendorRecomend;
