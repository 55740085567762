import React from "react";
import ConsumerCouponList from "../components/Couponlist/ConsumerCouponList";
import CouponList from "../components/Couponlist/CouponList";

function Coupons() {
  const userType = sessionStorage.getItem("type")
  return (
    <>
     {userType === "vendor" && ( <CouponList />)}
     {userType === "consumer" && (<ConsumerCouponList />)}
    </>
  );
}

export default Coupons;
