import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";

const TransactionAliasModal = (props) => {
  return (
    props.deviceDefaultTransaction && (
      <div>
        <Modal
          show={props.transactionAliasModal}
          size="md"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title" id="">
              Set an Alias for{" "}
              <span style={{ color: "red" }}>
                {props.deviceDefaultTransaction.orig_label}
              </span>
            </h5>
          </div>
          <Modal.Body>
            <input
              type="text"
              className="col-12"
              placeholder="Enter Alias"
              onChange={(e) => props.setTransactionAlias(e.target.value)}
              value={props.transactionAliasInput}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="sm"
              variant="secondary"
              onClick={() => props.setTransactionAliasModal(false)}
            >
              Close
            </Button>
            <Button
              size="sm"
              variant="primary"
              onClick={() =>
                props.saveDefaultTransaction(null, props.transactionAliasInput)
              }
            >
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  );
};

export default TransactionAliasModal;
