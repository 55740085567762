import React from "react";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";

const VendorDeviceList = (props) => {
  return (
    <div>
      <Modal
        show={props.deviceListModal}
        onHide={() => props.setDeviceListModal(!props.deviceListModal)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal-header">
          <div className="col-sm-12">
            <h5 className="modal-title" id="">
              Device List
            </h5>
          </div>
        </div>
        <Modal.Body>
          <Table striped bordered hover size="lg">
            <thead>
              <tr>
                <th>Serial</th>
                <th>Consumer</th>
                <th>Recommendations</th>
              </tr>
            </thead>
            <tbody>
              {props.assignedDevices.map((assignedDevice) => (
                <tr key={assignedDevice.api_key}>
                  <td>{assignedDevice.serial_number}</td>
                  <td>
                    <a
                      href="#"
                      onClick={() =>
                        props.onVerify(assignedDevice.serial_number, true, true)
                      }
                    >
                      {assignedDevice.owner_email}
                    </a>
                  </td>
                  <td>
                    <Button
                      className="btn-sm"
                      variant="primary"
                      onClick={() =>
                        props.onVerify(assignedDevice.serial_number, true)
                      }
                    >
                      Show
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => props.setDeviceListModal(!props.deviceListModal)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default VendorDeviceList;
