/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import CreatableSelect from "react-select/creatable";
let BASE_URL = "https://production.drfever.co/api";
  let BASE_DOMAIN = "https://production.drfever.co";
class AdditionalReading extends React.Component {
  state = {
    description: "",
    image: null,
    showDialog: false,
    uploading: false,
    additionalReadings: [],
    reading_other_actions: [],
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleImageChange = (e) => {
    this.setState({
      image: e.target.files[0],
    });
  };

  handleUploadingChange = (is_uploading) => {
    this.setState({
      uploading: is_uploading,
    });
  };

  resetUploadData = () => {
    this.handleUploadingChange(false);
    this.setState({
      description: "",
    });
    this.setState({
      image: null,
    });
    console.log("weee");
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let form_data = new FormData();
    if (!this.state.image) {
      this.setState({
        image: null,
      });
      return;
    }
    form_data.append("image", this.state.image, this.state.image.name);
    form_data.append("description", this.state.description);
    form_data.append("transaction", this.props.deviceDefaultTransaction.value);
    form_data.append("created_by", this.props.consumerID);
    let url = `${BASE_URL}/transaction_additional_reading/`;

    this.handleUploadingChange(true);

    axios
      .post(url, form_data, {
        headers: {
          "content-type": "multipart/form-data",
        },
      })
      .then((res) => {
        this.readAdditionalReadings();
        this.resetUploadData();
      })
      .catch((err) => {
        console.log(err);
        alert(err.response.data);
        this.handleUploadingChange(false);
      });
  };

  handleDialogState = (show) => {
    this.setState({
      ["showDialog"]: show,
    });

    this.readAdditionalReadings();
  };

  readAdditionalReadings = () => {
    axios
      .get(
        `${BASE_URL}/transaction_additional_reading/${this.props.deviceDefaultTransaction.value}/`
      )
      .then((res) => {
        this.setState({
          ["additionalReadings"]: res.data,
        });
      });
  };

  additionalReadingDelete = (reading_id) => {
    axios
      .delete(
        `${BASE_URL}/transaction_additional_reading/${reading_id}/`
      )
      .then((res) => {
        this.readAdditionalReadings();
      });
  };

  readTransactionReadingOtherActions = () => {
    axios
      .get(`${BASE_URL}/transaction_reading_other_actions/1`)
      .then((res) => {
        this.setState({
          ["reading_other_actions"]: res.data,
        });
      });
  };

  handleTransactionReadingOtherAction = (e, additionalReading) => {
    additionalReading.transaction_reading_other_action = e;

    let form_data = new FormData();
    form_data.append("update_transaction_reading_other_action", true);
    form_data.append("transaction_reading_other_action_id", null);
    form_data.append("transaction_additional_reading_id", additionalReading.id);

    if (e) {
      form_data.append("transaction_reading_other_action_id", e.id);
    }

    let url = `${BASE_URL}/transaction_additional_reading/`;

    axios.post(url, form_data, {
      headers: {
        "content-type": "multipart/form-data",
      },
    });
  };

  initiate = () => {
    this.readTransactionReadingOtherActions();
    console.log(this.props.consumerSetupAdditionalDeviceReading);
  };

  render() {
    return (
      <div>
        <Button
          onClick={() => this.handleDialogState(true)}
          style={{ width: "100%" }}
        >
          {this.props.consumerSetupAdditionalDeviceReading.title}
        </Button>
        <Modal
          show={this.state.showDialog}
          onShow={() => this.initiate()}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className="modal-header">
            <div className="col-sm-12">
              <h5 className="modal-title" id="">
                {this.props.consumerSetupAdditionalDeviceReading.title}
              </h5>
            </div>
          </div>
          <Modal.Body>
            <Table size="lg">
              <thead>
                <tr>
                  <th width="5%">#</th>
                  {!this.props.consumerSetupAdditionalDeviceReading
                    .description_checked && (
                    <th width="20%">
                      {
                        this.props.consumerSetupAdditionalDeviceReading
                          .description
                      }
                    </th>
                  )}

                  {!this.props.consumerSetupAdditionalDeviceReading
                    .attachment_checked && (
                    <th width="10%">
                      {
                        this.props.consumerSetupAdditionalDeviceReading
                          .attachment
                      }
                    </th>
                  )}

                  {!this.props.consumerSetupAdditionalDeviceReading
                    .user_checked && (
                    <th width="15%">
                      {this.props.consumerSetupAdditionalDeviceReading.user}
                    </th>
                  )}

                  {!this.props.consumerSetupAdditionalDeviceReading
                    .date_created_checked && (
                    <th width="15%">
                      {
                        this.props.consumerSetupAdditionalDeviceReading
                          .date_created
                      }
                    </th>
                  )}

                  {!this.props.consumerSetupAdditionalDeviceReading
                    .status_checked && (
                    <th width="25%">
                      {this.props.consumerSetupAdditionalDeviceReading.status}
                    </th>
                  )}

                  {sessionStorage.getItem("type") === "consumer" &&
                    !this.props.consumerSetupAdditionalDeviceReading
                      .action_checked && (
                      <th width="5%">
                        {this.props.consumerSetupAdditionalDeviceReading.action}
                      </th>
                    )}
                </tr>
              </thead>
              <tbody>
                {this.state.additionalReadings.map(
                  (additionalReading, index) => (
                    <tr key={additionalReading.id}>
                      <td>{index + 1}</td>

                      {!this.props.consumerSetupAdditionalDeviceReading
                        .description_checked && (
                        <td>{additionalReading.description}</td>
                      )}

                      {!this.props.consumerSetupAdditionalDeviceReading
                        .attachment_checked && (
                        <td>
                          <a
                            href={`${BASE_DOMAIN}${additionalReading.image}`}
                            target="_blank"
                          >
                            {additionalReading.file_extension === "image" && (
                              <img
                                width="100px"
                                height="80px"
                                src={`${BASE_DOMAIN}${additionalReading.image}`}
                              />
                            )}

                            {additionalReading.file_extension === "video" && (
                              <video width="100px" height="80px" controls>
                                <source
                                  src={`${BASE_DOMAIN}${additionalReading.image}`}
                                  type="video/mp4"
                                />
                              </video>
                            )}

                            {additionalReading.file_extension === "pdf" && (
                              <img
                                src="https://i.imgur.com/8SZIzse.jpg"
                                width="100px"
                                height="80px"
                              />
                            )}

                            {additionalReading.file_extension === "others" && (
                              <img
                                src="https://i.imgur.com/IzSv3Ss.jpg"
                                width="100px"
                                height="80px"
                              />
                            )}
                          </a>
                        </td>
                      )}

                      {!this.props.consumerSetupAdditionalDeviceReading
                        .user_checked && (
                        <td>{additionalReading.created_by}</td>
                      )}

                      {!this.props.consumerSetupAdditionalDeviceReading
                        .date_created_checked && (
                        <td>{additionalReading.created_at}</td>
                      )}

                      {!this.props.consumerSetupAdditionalDeviceReading
                        .status_checked && (
                        <td>
                          <div className="col-12">
                            <CreatableSelect
                              placeholder="Set Status"
                              isSearchable
                              isClearable
                              options={this.state.reading_other_actions}
                              defaultValue={
                                additionalReading.transaction_reading_other_action
                              }
                              onChange={(e) =>
                                this.handleTransactionReadingOtherAction(
                                  e,
                                  additionalReading
                                )
                              }
                            />
                          </div>
                        </td>
                      )}

                      {sessionStorage.getItem("type") === "consumer" &&
                        !this.props.consumerSetupAdditionalDeviceReading
                          .action_checked && (
                          <td>
                            <Button
                              size="sm"
                              style={{ marginTop: "5%" }}
                              variant="danger"
                              onClick={() =>
                                this.additionalReadingDelete(
                                  additionalReading.id
                                )
                              }
                            >
                              Delete
                            </Button>
                          </td>
                        )}
                    </tr>
                  )
                )}
              </tbody>
            </Table>

            {sessionStorage.getItem("type") === "consumer" && (
              <div className="ui main">
                <br />
                <hr />
                <b>New</b>

                <form onSubmit={this.handleSubmit}>
                  <p>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Description"
                      id="description"
                      value={this.state.description}
                      onChange={this.handleChange}
                      required
                    />
                  </p>
                  <p>
                    <input
                      type="file"
                      id="image"
                      accept="image/png, image/jpeg"
                      onChange={this.handleImageChange}
                      required
                    />

                    {!this.state.uploading && (
                      <Button variance="primary" type="submit" size="sm">
                        Upload
                      </Button>
                    )}

                    {this.state.uploading && (
                      <Button variance="primary" disabled size="sm">
                        Uploading {this.state.uploading}
                      </Button>
                    )}
                  </p>
                </form>
              </div>
            )}
          </Modal.Body>

          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => this.handleDialogState(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default AdditionalReading;
