import React from 'react'
import "bootstrap/dist/css/bootstrap.min.css";
import Password from '../ChangePassword/Password';

function AccountSettings() {
  sessionStorage.setItem("lastname", "Smith");
  return (
    <div>
      <div className="flex-container">
        <div className="m-3 mt-3 w-75 input-field">
          <label htmlFor="exampleInputEmail1" className="form-label">
            System Language
          </label>
          <select type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
            <option>English</option>
          </select>
          <div id="emailHelp" className="form-text"></div>
        </div>
        <div className="m-3 w-75 input-field">
          <label htmlFor="exampleInputEmail1" className="form-label">
            System Theme
          </label>
          <div className="form-group w-100" >
                      <input type='radio' className="form-check-input animate" name='system-theme' id='light' defaultChecked />
                      <label htmlFor="light" className="animate">
                        {" "}
                        &nbsp;Light Theme
                      </label>{" "}
                      &nbsp; &nbsp;
                      <input  className="form-check-input animate" type="radio" value="business" name="system-theme" id="dark" disabled />
                      <label htmlFor="dark" className="animate" style={{color: "#ccc"}}>
                        {" "}
                        &nbsp;Dark Theme
                      </label>{" "}
                      &nbsp;
                    </div>
          <div id="emailHelp" className="form-text"></div>
        </div>
      </div>
      <hr />
      <div className="flex-container">
        <div className="m-3 mt-3 w-50 input-field">
          <label htmlFor="exampleInputEmail1" className="form-label">
           Date Format
          </label>
          <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled />
          <div id="emailHelp" className="form-text"></div>
        </div>
        <div className="m-3 mt-3 w-50 input-field">
          <label htmlFor="exampleInputEmail1" className="form-label">
           Password
          </label>
          <Password/>
          <div id="emailHelp" className="form-text"></div>
        </div>
      </div>
      <hr />
      <br />
    </div>
  )
}

export default AccountSettings