// import logo from '../assets/images/logo.png';
import "../App.css";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import Navbar from "../components/navbar";
import axios from "axios";

import ConsumerSymptoms from "../components/ConsumerSymptoms";
import VendorRecommendations from "../components/VendorRecommendations";
import SymptomSearch from "../components/SymptomSearch";
import Login from "../components/Login";
import Registration from "../components/Registration";
import ConsumerDeviceList from "../components/ConsumerDeviceList";
import VendorDeviceList from "../components/VendorDeviceList";
import VendorCouponList from "../components/VendorCouponList";
import VendorConsumerList from "../components/VendorConsumerList";
import VendorModal from "../components/VendorModal";
import ConsumerModal from "../components/ConsumerModal";
import ConsumerCouponList from "../components/ConsumerCouponList";

function Home() {
  /*Device Data*/
  const [deviceConsumerEmail, setDeviceConsumerEmail] = useState("");
  const [deviceConsumerFirstName, setDeviceConsumerFirstName] = useState("");
  const [deviceConsumerLastName, setDeviceConsumerLastName] = useState("");
  const [deviceConsumerPhoneNumber, setDeviceConsumerPhoneNumber] =
    useState("");
  const [currentDeviceDefaultConsumer, setCurrentDeviceDefaultConsumer] =
    useState("");
  
  const [serialNumber, setSerialNumber] = useState("");
  // const [showRecommendations, setRecommendations] = useState(true)

  // const [showOtherSymptoms, setOtherSymptoms] = useState(true)
  const [deviceDefaultTransaction, setDeviceDefaultTransaction] = useState({});
  // const [transactionReading, setTransactionReading] = useState({})
  const [deviceTransactions, setDeviceTransactions] = useState(null);

  /* Consumer & Vendor */
  const [assignedDevices, setAssignedDevices] = useState([]);
  const [assignedCoupons, setAssignedCoupons] = useState([]);
  const [assignedConsumers, setAssignedConsumers] = useState([]);

  const [currentVendorCoupon, setCurrentVendorCoupon] = useState({});

  const [couponSetupModal, setCouponSetupModal] = useState(false);
  const [vendorCouponAddModal, setVendorCouponAddModal] = useState(false);
  const [userType, setUserType] = useState(null);

  /* Consumer */
  const [consumerID, setConsumerID] = useState("");
  const [consumerEmail, setConsumerEmail] = useState("");
  const [consumerFirstName, setConsumerFirstName] = useState("");
  const [consumerLastName, setConsumerLastName] = useState("");
  const [consumerCoupon, setConsumerCoupon] = useState("");
  const [consumerBypassOTP, setConsumerBypassOTP] = useState("");
  const [consumerDisableUpdateBypassOTP, setConsumerDisableUpdateBypassOTP] =
    useState(false);

  const [consumerCouponAddModal, setConsumerCouponAddModal] = useState(false);
  const [currentConsumerCoupon, setCurrentConsumerCoupon] = useState({});

  const [consumerSetupSymptoms, setConsumerSetupSymptoms] = useState({});
  const [consumerSetupDeviceList, setConsumerSetupDeviceList] = useState({});

  const [consumerSetupDeviceReading, setConsumerSetupDeviceReading] = useState(
    {}
  );

  const [consumerSetupDeviceReadingKeys, setConsumerSetupDeviceReadingKeys] =
    useState([]);

  const [
    consumerSetupAdditionalDeviceReading,
    setConsumerSetupAdditionalDeviceReading,
  ] = useState({});

  const [
    consumerSetupAdditionalDeviceReadingKeys,
    setConsumerSetupAdditionalDeviceReadingKeys,
  ] = useState([]);

  const [consumerPhoneNumber, setConsumerPhoneNumber] = useState(null);
  const [consumerDefaultDevice, setConsumerDefaultDevice] = useState({});

  /* Vendor */
  const [vendorID, setVendorID] = useState("");
  const [vendorCoupon, setVendorCoupon] = useState("");
  const [vendorEmail, setVendorEmail] = useState("");
  const [vendorFirstName, setVendorFirstName] = useState("");
  const [vendorLastName, setVendorLastName] = useState("");
  const [vendorPhoneNumber, setVendorPhoneNumber] = useState(null);
  const [vendorBypassOTP, setVendorBypassOTP] = useState("");

  /*Modals*/
  const [couponListModal, setCouponListModal] = useState(false);
  const [consumerCouponListModal, setConsumerCouponListModal] = useState(false);
  const [consumerListModal, setConsumerListModal] = useState(false);
  const [deviceListModal, setDeviceListModal] = useState(false);
  const [deviceConsumerListModal, setDeviceConsumerListModal] = useState(false);

  /*Modals*/
  const [businessSetupModal, setBusinessSetupModal] = useState(false);

  const currentURL = window.location.href;
  let BASE_URL = "https://production.drfever.co/api";
  let BASE_DOMAIN = "https://production.drfever.co";
  // if (currentURL.indexOf("localhost") !== -1) {
  //   BASE_URL = "http://localhost:8000/api";
  //   BASE_DOMAIN = "http://localhost:8000";
  // }

  if (currentURL.indexOf("staging") !== -1) {
    BASE_URL = "https://staging.drfever.co/api";
    BASE_DOMAIN = "https://staging.drfever.co";
  }

  const [productList, setProductList] = useState(() => {
    axios.get(`${BASE_URL}/product/`).then((res) => {
      sessionStorage.setItem("productList" ,res.data);
      readPagelabel();
      readBusinessTypes();
    });
  });

  function setNewVendorCoupon(e) {
    let value = e.target.value;
    if (e.target.attributes.type.value === "checkbox") {
      value = e.target.checked;
    }

    setCurrentVendorCoupon({
      ...currentVendorCoupon,
      [e.target.id]: value,
    });
  }

  function setNewConsumerCoupon(e) {
    let value = e.target.value;
    if (e.target.attributes.type.value === "checkbox") {
      value = e.target.checked;
    }

    setCurrentConsumerCoupon({
      ...currentConsumerCoupon,
      [e.target.id]: value,
    });
  }

  function setDefaultCoupon(coupon_code) {
    let params = {
      set_default_coupon: true,
      consumer: consumerID,
      code: coupon_code,
    };

    let url = `${BASE_URL}/coupon/${coupon_code}/`;
    axios.post(url, params).then((res) => {
      setAssignedCoupons(res.data.data);
      setConsumerCouponListModal(false);
    });
  }

  function onSendUpdateConsumerCoupon() {
    currentConsumerCoupon["consumer"] = consumerID;
    let url = `${BASE_URL}/coupon/${currentConsumerCoupon.id}/`;
    axios
      .post(url, currentConsumerCoupon)
      .then((res) => {
        setAssignedCoupons(res.data.data);
        setConsumerCouponAddModal(!consumerCouponAddModal);
      })
      .catch((e) => {
        if (e.response.status === 400) {
          alert(e.response.data);
        } else {
          alert("Something went wrong.");
        }
      });
  }

  function onSendUpdateCoupon() {
    currentVendorCoupon["vendor"] = vendorID;
    let url = `${BASE_URL}/coupon/${currentVendorCoupon.id}/`;
    axios
      .post(url, currentVendorCoupon)
      .then((res) => {
        setAssignedCoupons(res.data.data);
        setVendorCouponAddModal(!vendorCouponAddModal);
      })
      .catch((e) => {
        if (e.response.status === 400) {
          alert(e.response.data);
        } else {
          alert("Something went wrong.");
        }
      });
  }

  function openAccountSetupModal(show, vendor_coupon) {
    if (show) {
      setCurrentVendorCoupon(vendor_coupon);
    } else {
      setCurrentVendorCoupon({});
    }
    setCouponSetupModal(show);
  }

  const [pageLabel, setPageLabel] = useState({});

  function readPagelabel() {
    axios.get(`${BASE_URL}/page_label/`).then((res) => {
      setPageLabel(res.data);
    });
  }
  const [businessType, setBusinessType] = useState([]);

  function readBusinessTypes() {
    axios.get(`${BASE_URL}/business_type/`).then((res) => {
      setBusinessType(res.data);
    });
  }

  /* Device */
  const [deviceModal, setDeviceModal] = useState(false);
  const [deviceConsumerDataModal, setDeviceConsumerDataModal] = useState(false);

  const showDeviceDialog = () => {
    setDeviceModal(!deviceModal);
  };

  const showDeviceConsumerDataDialog = () => {
    setDeviceConsumerDataModal(!deviceConsumerDataModal);
  };

  const setDeviceData = (data, from_consumer_or_vendor) => {
    setDeviceConsumerEmail(data.email);
    setDeviceConsumerFirstName(data.first_name);
    setDeviceConsumerLastName(data.last_name);
    setDeviceConsumerPhoneNumber(data.phone_number);
    setCurrentDeviceDefaultConsumer(data.default_consumer);
    sessionStorage.setItem("deviceDefaultTransaction" ,data.device_default_transaction);
    sessionStorage.setItem("deviceTransactions", data.device_transactions);
    setAssignedCoupons(data.assigned_coupons);

    console.log(data.assigned_coupons);

    // setTransactionReading(data.device_default_transaction.transaction_reading)

    showDeviceDialog();
  };

  const onSendUpdateDevice = () => {
    axios
      .put(`${BASE_URL}/device/${serialNumber}/`, {
        consumer_email: deviceConsumerEmail,
        consumer_first_name: deviceConsumerFirstName,
        consumer_last_name: deviceConsumerLastName,
        consumer_phone_number: deviceConsumerPhoneNumber,
        device_default_transaction: deviceDefaultTransaction,
      })
      .then((res) => {
        setConsumerModal(false);
        setVendorModal(false);
        setDeviceModal(false);
        setDeviceConsumerDataModal(false);

        setSerialNumber("");
      });
  };

  /* Consumer */
  const [consumerModal, setConsumerModal] = useState(false);

  const showConsumerDialog = () => {
    setConsumerModal(!consumerModal);
  };

  const setConsumerData = (data) => {
    setConsumerID(data.id);
    setConsumerEmail(data.email);
    setConsumerFirstName(data.first_name);
    setConsumerLastName(data.last_name);
    setConsumerCoupon(data.coupon);
    setConsumerBypassOTP(data.bypass_otp);
    setConsumerDisableUpdateBypassOTP(data.disable_update_bypass_otp);
    sessionStorage.setItem("consumerSetupDeviceReading", JSON.stringify(data.account_setup.device_reading));
    sessionStorage.setItem("consumerSetupDeviceReadingKeys",JSON.stringify(data.account_setup.device_reading_keys));
    sessionStorage.setItem("consumerSetupAdditionalDeviceReading",
      JSON.stringify(data.account_setup.additional_device_reading)
    );
    sessionStorage.setItem("consumerSetupAdditionalDeviceReadingKeys",
      JSON.stringify(data.account_setup.additional_device_reading_keys)
    );

    sessionStorage.setItem( "consumerSetupSymptoms",data.account_setup.symptoms);
    sessionStorage.setItem("consumerSetupDeviceList",data.account_setup.device_list);

    setConsumerPhoneNumber(data.phone_number);
    setConsumerDefaultDevice(data.default_consumer_device);
    setAssignedDevices(data.assigned_devices);
    setAssignedCoupons(data.assigned_coupons);
  };

  function consumerApproveChanged(record, e) {
    let params = {
      vendor_consumer_update: true,
      vendor_id: vendorID,
    };
    params[e.target.name] = e.target.checked;

    axios.put(`${BASE_URL}/consumer/${record.id}/`, params).then((res) => {
      console.log(res);
    });
  }

  const onSendUpdateConsumerData = () => {
    axios
      .put(`${BASE_URL}/consumer/${consumerID}/`, {
        email: consumerEmail,
        first_name: consumerFirstName,
        last_name: consumerLastName,
        phone_number: consumerPhoneNumber,
        bypass_otp: consumerBypassOTP,
      })
      .then((res) => {
        alert(res.data.message);
        setConsumerModal(!consumerModal);
      });
  };

  /* Vendor */
  const [vendorModal, setVendorModal] = useState(false);

  const showVendorDialog = () => {
    setVendorModal(!vendorModal);
  };

  const setVendorData = (data) => {
    setVendorID(data.id);
    setVendorCoupon(data.coupon);
    setVendorBypassOTP(data.bypass_otp);
    setVendorEmail(data.email);
    setVendorFirstName(data.first_name);
    setVendorLastName(data.last_name);
    setVendorPhoneNumber(data.phone_number);
    setAssignedDevices(data.assigned_devices);
    setAssignedCoupons(data.assigned_coupons);
    setAssignedConsumers(data.assigned_consumers);
  };

  const onSendUpdateVendorData = () => {
    axios
      .put(`${BASE_URL}/vendor/${vendorID}/`, {
        email: vendorEmail,
        first_name: vendorFirstName,
        last_name: vendorLastName,
        phone_number: vendorPhoneNumber,
        bypass_otp: vendorBypassOTP,
      })
      .then((res) => {
        alert(res.data.message);
        setVendorModal(!vendorModal);
      });
  };

  /* Common */

  const [LoginInput, setLoginInput] = useState("");
  const [LoginAsBusiness, setLoginAsBusiness] = useState(false);
  const [LoginModal, setLoginModal] = useState(false);
  const [LoginStatus, setLogInStatus] = useState("Login");
  const [otpModal, setOtpModal] = useState(false);
  const [otpInput, setOtpInput] = useState("");

  const openLoginModal = () => {
    setLoginModal(true);
    setLoginInput("");
  };

  const onLoginSubmit = (verify_otp) => {
    let url = `${BASE_URL}/login/${LoginInput}/`;
    if (verify_otp) {
      url = `${BASE_URL}/login/${LoginInput}/${otpInput}/`;
    }

    setLogInStatus("Submitting");
    axios
      .get(url)
      .then((res) => {
        if (res.data !== "verify_otp") {
          let response_data = res.data;
          setUserType(response_data.type);
          if (response_data.type === "vendor") {
            setVendorData(response_data);
            showVendorDialog();
          } else if (response_data.type === "consumer") {
            setConsumerData(response_data);
            showConsumerDialog();
          }
          setLogInStatus("Submit");
          setOtpModal(false);
          setLoginModal(false);
        } else {
          setLogInStatus("Submit");
          setOtpInput("");
          setOtpModal(true);
        }
      })
      .catch((e) => {
        setLogInStatus("Submit");
        if (e.response.status === 400) {
          alert(e.response.data);
          if (e.response.data === "OTP is expired.") {
            setOtpModal(false);
          }
        } else {
          if (verify_otp) {
            alert("OTP is incorrect.");
          }
        }
        console.log("e", e);
      });
  };

  const [searchText, setSearchText] = useState("");
  const [searchLabel, setSearchLabel] = useState("Search");
  
  const onSearchSubmit = () => {
    let url = `${BASE_URL}/search/${searchText}/`;

    setSearchLabel("Searching...");
    axios
    .get(url)
    .then((res) => {
      let response_data = res.data;
      setSymptomsSearchDetails(response_data);
      setSymptomsSearchModal(true);
      setSearchLabel("Search");
    })
    .catch((e) => {
      alert("Search not found.");
      setSearchLabel("Search");
      console.log("e", e);
    });
  }



  const onVerify = (
    serial_number,
    from_consumer_or_vendor,
    consumer_record_only
  ) => {
    if (!serial_number) {
      serial_number = serialNumber;
    }

    if (from_consumer_or_vendor) {
      setSerialNumber((prevSerialNumber) => {
        return serial_number;
      });
    }

    const url = `${BASE_URL}/device/${serial_number}/`;
    axios
      .get(url)
      .then((res) => {
        let response_data = res.data;
        if (response_data.type === "device") {
          if (from_consumer_or_vendor) {
            setDeviceData(response_data.data);
            if (consumer_record_only) {
              showDeviceConsumerDataDialog();
            } else {
              if (response_data.account_setup) {
                setConsumerSetupDeviceReading(
                  response_data.account_setup.device_reading
                );
                setConsumerSetupDeviceReadingKeys(
                  response_data.account_setup.device_reading_keys
                );
                setConsumerSetupAdditionalDeviceReading(
                  response_data.account_setup.additional_device_reading
                );
                setConsumerSetupAdditionalDeviceReadingKeys(
                  response_data.account_setup.additional_device_reading_keys
                );
                setConsumerSetupSymptoms(response_data.account_setup.symptoms);
              }
              showDeviceDialog();
              setSerialNumber((prevSerialNumber) => {
                return "";
              });
            }
          } else {
            alert("Search not found.");
          }
        }
      })
      .catch((e) => {
        alert("Search not found.");
        console.log("e", e);
      });
  };

  const update_search_ = (serial_number) => {
    setSerialNumber((prevSerialNumber) => {
      return serial_number;
    });
  };




  const update_serial_number = (serial_number) => {
    setSerialNumber((prevSerialNumber) => {
      return serial_number;
    });
  };

  const onHideConsumerModal = () => {
    update_serial_number("");
    setConsumerModal(!consumerModal);
  };

  const showConsumerDeviceList = (device_serial_number) => {
    if (device_serial_number) {
      onVerify(device_serial_number, true, false);
    } else if (assignedDevices.length === 1) {
      onVerify(assignedDevices[0].serial_number, true);
    } else {
      setDeviceConsumerListModal(!deviceConsumerListModal);
    }
  };

  const deviceReadingOnChange = (e) => {
    sessionStorage.setItem("deviceDefaultTransaction" ,e);
  };

  const openRegisterModal = () => {
    setRegisterModal(true);
  };

  const [registerModal, setRegisterModal] = useState(false);


  const [symptomsSearchModal, setSymptomsSearchModal] = useState(false);
  const [symptomsSearchDetails, setSymptomsSearchDetails] = useState({
    recommended_drugs: [],
  });

  return (
    <>
      <div className="App">
        {/*<h1 className='title'>Wellness Device build for care</h1>*/}
        <h1 className="title">
          <span>
            {pageLabel.landing_page_header} &nbsp;
            {userType === "consumer" && (
              <span>
                {consumerFirstName} {consumerLastName}!
              </span>
            )}
            {userType === "vendor" && (
              <span>
                {vendorFirstName} {vendorLastName}!
              </span>
            )}
          </span>
        </h1>

        <div>
          <input
            type="text"
            className="search-input"
            placeholder="Enter Symptoms"
            onChange={(e) => setSearchText(e.target.value)}
            onKeyDown={(e) => {
              if (e.code === "Enter") {
                e.preventDefault();
                onSearchSubmit();
              }
            }}
            value={searchText}
          />
          <br />
          <Button
            className="col btn btn-pink-moon btn-rounded"
            variant="danger"
            onClick={() => onSearchSubmit()}
          >
            {searchLabel}
          </Button>
        </div>
        <div className="bottom-content">
          <span>Copyright ©2022 All rights reserved</span>
        </div>
      </div>

      <SymptomSearch
        symptomsSearchModal={symptomsSearchModal}
        setSymptomsSearchModal={setSymptomsSearchModal}
        symptomsSearchDetails={symptomsSearchDetails}
      />

      {/* DEVICE Customer MODAL */}

      {/*CUSTOMER SYMPTOMS*/}

      <ConsumerSymptoms
        showConsumerDeviceList={showConsumerDeviceList}
        setSerialNumber={setSerialNumber}
        serialNumber={serialNumber}
        deviceTransactions={deviceTransactions}
        userType={userType}
        deviceModal={deviceModal}
        setDeviceModal={setDeviceModal}
        deviceConsumerListModal={deviceConsumerListModal}
        deviceDefaultTransaction={deviceDefaultTransaction}
        setDeviceConsumerListModal={setDeviceConsumerListModal}
        consumerID={consumerID}
        consumerFirstName={consumerFirstName}
        consumerLastName={consumerLastName}
        deviceReadingOnChange={deviceReadingOnChange}
        consumerDefaultDevice={consumerDefaultDevice}
        currentDeviceDefaultConsumer={currentDeviceDefaultConsumer}
        BASE_DOMAIN={BASE_DOMAIN}
        BASE_URL={BASE_URL}
        consumerSetupAdditionalDeviceReading={
          consumerSetupAdditionalDeviceReading
        }
        consumerSetupAdditionalDeviceReadingKeys={
          consumerSetupAdditionalDeviceReadingKeys
        }
        consumerSetupDeviceReadingKeys={consumerSetupDeviceReadingKeys}
        consumerSetupDeviceReading={consumerSetupDeviceReading}
        consumerSetupSymptoms={consumerSetupSymptoms}
      />

      <VendorRecommendations
        serialNumber={serialNumber}
        deviceTransactions={deviceTransactions}
        productList={productList}
        userType={userType}
        deviceModal={deviceModal}
        setDeviceModal={setDeviceModal}
        deviceDefaultTransaction={deviceDefaultTransaction}
        vendorFirstName={vendorFirstName}
        vendorLastName={vendorLastName}
        BASE_DOMAIN={BASE_DOMAIN}
        BASE_URL={BASE_URL}
        consumerSetupAdditionalDeviceReading={
          consumerSetupAdditionalDeviceReading
        }
        consumerSetupAdditionalDeviceReadingKeys={
          consumerSetupAdditionalDeviceReadingKeys
        }
        consumerSetupDeviceReadingKeys={consumerSetupDeviceReadingKeys}
        consumerSetupDeviceReading={consumerSetupDeviceReading}
        consumerSetupSymptoms={consumerSetupSymptoms}
      />

      {/* Consumer Modal */}

      <ConsumerModal
        consumerModal={consumerModal}
        BASE_URL={BASE_URL}
        onHideConsumerModal={onHideConsumerModal}
        consumerID={consumerID}
        consumerCoupon={consumerCoupon}
        consumerSetupAdditionalDeviceReading={
          consumerSetupAdditionalDeviceReading
        }
        consumerSetupAdditionalDeviceReadingKeys={
          consumerSetupAdditionalDeviceReadingKeys
        }
        consumerSetupDeviceReadingKeys={consumerSetupDeviceReadingKeys}
        consumerSetupDeviceReading={consumerSetupDeviceReading}
        consumerSetupDeviceList={consumerSetupDeviceList}
        consumerSetupSymptoms={consumerSetupSymptoms}
        consumerFirstName={consumerFirstName}
        consumerLastName={consumerLastName}
        businessSetupModal={businessSetupModal}
        setBusinessSetupModal={setBusinessSetupModal}
        showConsumerDeviceList={showConsumerDeviceList}
        setConsumerEmail={setConsumerEmail}
        consumerEmail={consumerEmail}
        setConsumerBypassOTP={setConsumerBypassOTP}
        consumerDisableUpdateBypassOTP={consumerDisableUpdateBypassOTP}
        consumerBypassOTP={consumerBypassOTP}
        setConsumerFirstName={setConsumerFirstName}
        setConsumerLastName={setConsumerLastName}
        setConsumerPhoneNumber={setConsumerPhoneNumber}
        consumerPhoneNumber={consumerPhoneNumber}
        setConsumerModal={setConsumerModal}
        onSendUpdateConsumerData={onSendUpdateConsumerData}
        LoginAsBusiness={LoginAsBusiness}
        consumerCouponListModal={consumerCouponListModal}
        setConsumerCouponListModal={setConsumerCouponListModal}
      />

      <ConsumerCouponList
        consumerCouponListModal={consumerCouponListModal}
        setConsumerCouponListModal={setConsumerCouponListModal}
        assignedCoupons={assignedCoupons}
        consumerID={consumerID}
        consumerFirstName={consumerFirstName}
        consumerLastName={consumerLastName}
        consumerCouponAddModal={consumerCouponAddModal}
        setConsumerCouponAddModal={setConsumerCouponAddModal}
        setNewConsumerCoupon={setNewConsumerCoupon}
        currentConsumerCoupon={currentConsumerCoupon}
        setCurrentConsumerCoupon={setCurrentConsumerCoupon}
        onSendUpdateConsumerCoupon={onSendUpdateConsumerCoupon}
        setDefaultCoupon={setDefaultCoupon}
      />

      {/* Vendor Modal */}

      <VendorModal
        vendorFirstName={vendorFirstName}
        vendorCoupon={vendorCoupon}
        vendorLastName={vendorLastName}
        setDeviceListModal={setDeviceListModal}
        couponListModal={couponListModal}
        consumerListModal={consumerListModal}
        setConsumerListModal={setConsumerListModal}
        setCouponListModal={setCouponListModal}
        deviceListModal={deviceListModal}
        setVendorEmail={setVendorEmail}
        vendorBypassOTP={vendorBypassOTP}
        setVendorBypassOTP={setVendorBypassOTP}
        vendorEmail={vendorEmail}
        setVendorCoupon={setVendorCoupon}
        setVendorFirstName={setVendorFirstName}
        setVendorLastName={setVendorLastName}
        setVendorPhoneNumber={setVendorPhoneNumber}
        vendorPhoneNumber={vendorPhoneNumber}
        setVendorModal={setVendorModal}
        vendorModal={vendorModal}
        onSendUpdateVendorData={onSendUpdateVendorData}
      />

      {/* Vendor Device List Modal */}
      <VendorCouponList
        BASE_URL={BASE_URL}
        couponListModal={couponListModal}
        setCouponListModal={setCouponListModal}
        assignedCoupons={assignedCoupons}
        currentVendorCoupon={currentVendorCoupon}
        setCurrentVendorCoupon={setCurrentVendorCoupon}
        openAccountSetupModal={openAccountSetupModal}
        couponSetupModal={couponSetupModal}
        vendorID={vendorID}
        vendorEmail={vendorEmail}
        vendorFirstName={vendorFirstName}
        vendorLastName={vendorLastName}
        vendorCouponAddModal={vendorCouponAddModal}
        setVendorCouponAddModal={setVendorCouponAddModal}
        setNewVendorCoupon={setNewVendorCoupon}
        onSendUpdateCoupon={onSendUpdateCoupon}
      />

      <VendorConsumerList
        consumerListModal={consumerListModal}
        vendorID={vendorID}
        vendorEmail={vendorEmail}
        vendorFirstName={vendorFirstName}
        vendorLastName={vendorLastName}
        consumerApproveChanged={consumerApproveChanged}
        setConsumerListModal={setConsumerListModal}
        assignedConsumers={assignedConsumers}
      />

      {/* Vendor Device List Modal */}
      <VendorDeviceList
        deviceListModal={deviceListModal}
        setDeviceListModal={setDeviceListModal}
        assignedDevices={assignedDevices}
        onVerify={onVerify}
      />

      {/* Consumer Device List Modal */}
      <ConsumerDeviceList
        deviceConsumerListModal={deviceConsumerListModal}
        setDeviceConsumerListModal={setDeviceConsumerListModal}
        assignedDevices={assignedDevices}
        onVerify={onVerify}
        consumerSetupDeviceList={consumerSetupDeviceList}
      />

      {/* Registration Modal */}

      {/* Login Modal */}
    </>
  );
}

export default Home;
