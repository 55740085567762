import React from "react";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "../../../helpers/FormHelpers";

function AdditionalReading() {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <div>
       <div className="m-3 form-check">
    <input {...register("showAdditionalReading")} type="checkbox" className="form-check-input" id="exampleCheck1"/>
    <label className="form-check-label" htmlFor="exampleCheck1">Show</label>
  </div>
      <div className="flex-container">
        <div className="m-3 mt-3 w-75 input-field">
          <label htmlFor="exampleInputEmail1" className="form-label">
            Title
          </label>
          <input type="text" {...register("additionalReadingTitle", { required: false })} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
          {errors.deviceReadingTitle && <ErrorMessage message={errors.deviceReadingTitle.message} />}
        </div>
        <div className="m-3 w-75 input-field">
          <label htmlFor="exampleInputEmail1" className="form-label">
            Description
          </label>
          <input type="text" {...register("description", { required: false })} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
          {errors.co2 && <ErrorMessage message={errors.co2.message} />}
        </div>
      </div>
      <hr />
      <div className="flex-container">
        <div className="m-3 mt-3 w-75 input-field">
          <label htmlFor="exampleInputEmail1" className="form-label">
            Attachment
          </label>
          <input type="text" {...register("attachment", { required: false })} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
          {errors.attachment && <ErrorMessage message={errors.attachment.message} />}
        </div>
        <div className="m-3 mt-3 w-75 input-field">
          <label htmlFor="exampleInputEmail1" className="form-label">
            User
          </label>
          <input type="text" {...register("user", { required: false })} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
          {errors.user && <ErrorMessage message={errors.user.message} />}
        </div>
      </div>
      <hr />
      <div className="flex-container">
        <div className="m-3 mt-3 w-75 input-field">
          <label htmlFor="exampleInputEmail1" className="form-label">
            Date Created
          </label>
          <input type="date" {...register("dateCreated", { required: false })} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
          {errors.dateCreated && <ErrorMessage message={errors.dateCreated.message} />}
        </div>
        <div className="m-3 mt-3 w-75 input-field">
          <label htmlFor="exampleInputEmail1" className="form-label">
            Status
          </label>
          <input type="text" {...register("status", { required: false })} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
          {errors.status && <ErrorMessage message={errors.status.message} />}
        </div>
      </div>
      <hr />
      <div className="flex-container">
        <div className="m-3 mt-3 w-75 input-field">
          <label htmlFor="exampleInputEmail1" className="form-label">
            Action
          </label>
          <input type="text" {...register("action", { required: false })} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
          {errors.action && <ErrorMessage message={errors.action.message} />}
        </div>
        <div className="m-3 mt-3 w-75 input-field">
          <label htmlFor="exampleInputEmail1" className="form-label">
            File Type
          </label>
          <div className="form-check">
            <input {...register("image")} className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
            <label className="form-check-label" htmlFor="defaultCheck1">
              Image(jpg.gif,etc.)
            </label>
          </div>
          <div className="form-check">
            <input {...register("video")} className="form-check-input" type="checkbox" value="" id="defaultCheck2" />
            <label className="form-check-label" htmlFor="defaultCheck2">
              Video(mp4,mov,etc.)
            </label>
          </div>
          <div className="form-check">
            <input {...register("others")} className="form-check-input" type="checkbox" value="" id="defaultCheck1" />
            <label className="form-check-label" htmlFor="defaultCheck1">
              Others
            </label>
            
            {errors.voc && <ErrorMessage message={errors.voc.message} />}
          </div>
        </div>
      </div>
      <br />
    </div>
  );
}

export default AdditionalReading;
