import React from "react";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import { FaPlusCircle } from "react-icons/fa";
import ConsumerCouponAdd from "../components/ConsumerCouponAdd";

const ConsumerCouponList = (props) => {
  return (
    <div>
      <Modal
        show={props.consumerCouponListModal}
        onHide={() =>
          props.setConsumerCouponListModal(!props.consumerCouponListModal)
        }
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal-header">
          <div className="col-sm-6">
            <h5 className="modal-title" id="">
              Consumer Coupons
            </h5>
          </div>
          <div className="col-sm-1 col-sm-offset-5">
            <Button
              size="sm"
              variant="info"
              style={{ width: "60px" }}
              onClick={(e) => {
                props.setConsumerCouponAddModal(true);
              }}
            >
              <FaPlusCircle />
            </Button>
          </div>
        </div>
        <Modal.Body>
          <Table striped bordered hover size="lg">
            <thead>
              <tr>
                <th>Code</th>
                <th>Descriptions</th>
                <th>Use Coupon</th>
              </tr>
            </thead>
            <tbody>
              {props.assignedCoupons &&
                props.assignedCoupons.map((record) => (
                  <tr key={record.id}>
                    <td>{record.code}</td>
                    <td>{record.description}</td>
                    {!record.default && (
                      <td>
                        <Button
                          size="sm"
                          style={{ marginTop: "5%" }}
                          variant="success"
                          onClick={() => props.setDefaultCoupon(record.code)}
                        >
                          Switch
                        </Button>
                      </td>
                    )}
                    {record.default && (
                      <td>
                        <Button
                          size="sm"
                          style={{ marginTop: "5%" }}
                          variant="dark"
                        >
                          Active
                        </Button>
                      </td>
                    )}
                  </tr>
                ))}
            </tbody>
          </Table>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() =>
              props.setConsumerCouponListModal(!props.consumerCouponListModal)
            }
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <div className="col-sm-offset-2 col-sm-4">
        <h5 className="modal-title" id="">
          <ConsumerCouponAdd
            currentConsumerCoupon={props.currentConsumerCoupon}
            consumerCouponAddModal={props.consumerCouponAddModal}
            setConsumerCouponAddModal={props.setConsumerCouponAddModal}
            setNewConsumerCoupon={props.setNewConsumerCoupon}
            onSendUpdateConsumerCoupon={props.onSendUpdateConsumerCoupon}
          />
        </h5>
      </div>
    </div>
  );
};

export default ConsumerCouponList;
