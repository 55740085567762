import axios from "axios";
import React, { useEffect, useState } from "react";
import { Table } from "react-bootstrap";
import { IoPeople } from "react-icons/io5";
import Pagination from "../Pagination/Pagination";

let BASE_URL = "https://production.drfever.co/api";

function ConsumerList() {
  const [assignedConsumers, setAssignedConsumers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [firstRow, setFirstRow] = useState(0)
  const [lastRow, setLastRow] = useState(9)
  const [rerenderPage, setRerenderPage] = useState(true)
  useEffect(() => {
    setAssignedConsumers(JSON.parse(sessionStorage.getItem("assigned_consumers")));
  }, []);

  const consumerApproveChanged = (record, e) => {
    let params = {
      vendor_consumer_update: true,
      vendor_id: sessionStorage.getItem("id"),
    };
    params[e.target.name] = e.target.checked;

    axios.put(`${BASE_URL}/consumer/${record.id}/`, params).then((res) => {
      console.log(res);
    });
  };

  const pageRender = (page) => {
    setCurrentPage(page); 
    setFirstRow(0 + (10*(currentPage - 1)))
    setLastRow(9 + (10*(currentPage - 1)))
    console.log(firstRow + " " + lastRow);
  }
useEffect(() => {
    setRerenderPage(false)
    setRerenderPage(true)
}, [lastRow])
  return (
    <div>
      <div className="text-base text-black m-3 font-weight-bold">
        <IoPeople style={{ marginBottom: "5px", fontWeight: "bold" }} />{" "}
        <label to="/dashboard" style={{ fontWeight: "bold" }}>
          Peolple
        </label>
      </div>
      <div className="m-4 rounded " style={{ backgroundColor: "#F6FFFf", width: "95%" }}>
        {rerenderPage && (<Table responsive hover size="lg" >
          <thead>
            <tr>
              <th>Firstname</th>
              <th>Lastname</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Coupon Used</th>
              <th style={{textAlign: "center"}}>Approved</th>
              <th style={{textAlign: "center"}}>Editable OTP</th>
            </tr>
          </thead>
          <tbody>
            {assignedConsumers.slice(firstRow, lastRow).map((record) => (
              <tr key={record.id}>
                <td>{record.first_name}</td>
                <td>{record.last_name}</td>
                <td>{record.email}</td>
                <td>{record.phone_number}</td>
                <td >{record.coupon_display}</td>
                <td style={{textAlign: "center"}}>
                  <input name="approved" type="checkbox" value="approved" defaultChecked={record.approved} onChange={(e) => consumerApproveChanged(record, e)} />
                </td>
                <td style={{textAlign: "center"}}>
                  <input name="disable_update_bypass_otp" type="checkbox" value="disable_update_bypass_otp" defaultChecked={record.disable_update_bypass_otp} onChange={(e) => consumerApproveChanged(record, e)} />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>)}
        <div style={{float: 'right'}}>
        <Pagination currentPage={currentPage} total={assignedConsumers.length} limit={10} onPageChange={(page) => {pageRender(page)}} />
        </div>
      </div>
    </div>
  );
}

export default ConsumerList;
