import React from 'react'
import { useFormContext } from 'react-hook-form';
import { ErrorMessage } from '../../../helpers/FormHelpers';

function Symptoms() {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <div>
       <div className="m-3 form-check">
    <input {...register("showSymptoms")}  type="checkbox" className="form-check-input" id="exampleCheck1"/>
    <label className="form-check-label" htmlFor="exampleCheck1">Show</label>
  </div>
      <div className="flex-container">
        <div className="m-3 mt-3 w-50 input-field">
          <label htmlFor="exampleInputEmail1" className="form-label">
            Title
          </label>
          <input type="text" {...register("symptomTitle", { required: false})} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
          {errors.symptomTitle && <ErrorMessage message={errors.symptomTitle.message} />}
        </div>
      </div>
     
      <br />
    </div>
  )
}

export default Symptoms