import React from "react";
import { Modal } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import BusinessSetup from "../components/BusinessSetup";

const ConsumerModal = (props) => {
  return (
    <div>
      <Modal
        show={props.consumerModal}
        size="lg"
        onHide={() => props.onHideConsumerModal()}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <div className="modal-header">
          <div className="col-sm-6">
            <h5 className="modal-title" id="">
              Welcome {props.consumerFirstName} {props.consumerLastName}!
            </h5>
          </div>
        </div>
        <Modal.Body>
          <div className="row">
            <div className="col-sm-4">
              <button
                className="btn-info"
                onClick={() => props.showConsumerDeviceList()}
              >
                Show Assigned Devices
              </button>
            </div>
            <div className="col-sm-4">
              <button
                className="btn-info"
                onClick={() =>
                  props.setConsumerCouponListModal(
                    !props.consumerCouponListModal
                  )
                }
              >
                Assigned Coupons
              </button>
            </div>
          </div>
          <br />
          <br />

          <div className="col-12">
            <span className="col-12">Email Address</span>
            <input
              type="text"
              className="col-12 email-input"
              onChange={(e) => props.setConsumerEmail(e.target.value)}
              value={props.consumerEmail}
              placeholder="Email Address"
            />
          </div>

          <br />

          <div className="col-12">
            <span className="col-12">First Name</span>
            <input
              type="text"
              className="col-12 email-input"
              onChange={(e) => props.setConsumerFirstName(e.target.value)}
              value={props.consumerFirstName}
              placeholder="First Name"
            />
          </div>

          <br />

          <div className="col-12">
            <span className="col-12">Last Name</span>
            <input
              type="text"
              className="col-12 email-input"
              onChange={(e) => props.setConsumerLastName(e.target.value)}
              value={props.consumerLastName}
              placeholder="Last Name"
            />
          </div>

          <br />

          <div className="col-12">
            <span className="col-12">Phone Number</span>
            <input
              type="text"
              className="col-12 email-input"
              onChange={(e) => props.setConsumerPhoneNumber(e.target.value)}
              value={props.consumerPhoneNumber}
              placeholder="Phone Number"
            />
          </div>

          <br />

          <div className="col-12">
            <span className="col-12">Bypass OTP code</span>
            <input
              type="text"
              className="col-12 email-input"
              disabled={props.consumerDisableUpdateBypassOTP}
              onChange={(e) => props.setConsumerBypassOTP(e.target.value)}
              value={props.consumerBypassOTP}
              placeholder="Bypass OTP"
            />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => props.setConsumerModal(!props.consumerModal)}
          >
            Close
          </Button>
          <Button
            variant="success"
            onClick={() => props.onSendUpdateConsumerData()}
            style={{ width: 150 }}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default ConsumerModal;
