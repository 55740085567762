import React from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { useState } from "react";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CreatableSelect from "react-select/creatable";

import DeviceReading from "../components/DeviceReading";

class VendorRecommendations extends React.Component {
  state = {
    transactionID: null,
    description: "",
    transactionSymptoms: [],
  };

  onBlurRecommendation = (e, transactionSymptom) => {
    axios
      .put(
        `${this.props.BASE_URL}/transaction_symptoms/${transactionSymptom.transaction}/`,
        {
          id: transactionSymptom.id,
          recommendation: e.target.value,
        }
      )
      .then((res) => {
        // this.readConsumerSymptoms();
      });
  };

  handleRecommendationChange = (e, transactionSymptom) => {
    transactionSymptom.recommendation = e.target.value;
    const recommendation_id = `recommendation_${transactionSymptom.id}`;

    this.setState({
      [recommendation_id]: {
        ...this.state[recommendation_id],
        recommendation: e.target.value,
      },
    });
  };

  handleProductChange = (e, transactionSymptom) => {
    if (e) {
      transactionSymptom.product = e;
    } else {
      transactionSymptom.product = {};
    }

    const product_id = `product_${transactionSymptom.id}`;

    this.setState({
      [product_id]: {
        ...this.state[product_id],
        product: transactionSymptom.product,
      },
    });

    axios
      .put(
        `${this.props.BASE_URL}/transaction_symptoms/${transactionSymptom.transaction}/`,
        {
          id: transactionSymptom.id,
          product: transactionSymptom.product,
        }
      )
      .then((res) => {});
  };

  handleTransactionChange = (e) => {
    this.setState({
      ["transactionID"]: e.value,
    });

    this.readConsumerSymptoms(e.value);
    // axios.post(`${this.props.BASE_URL}/device/${this.props.serialNumber}/`, {
    //     transactionID: e.value,
    // }).then(res => {
    // })
  };

  addConsumerSymptoms = () => {
    this.setState({
      ["description"]: "",
    });

    axios
      .put(
        `${this.props.BASE_URL}/transaction_symptoms/${this.state.transactionID}/`,
        {
          description: this.state.description,
        }
      )
      .then((res) => {
        this.readConsumerSymptoms();
      });
  };

  readConsumerSymptoms = (transactionID) => {
    if (!transactionID) {
      transactionID = this.state.transactionID;
    }

    axios
      .get(`${this.props.BASE_URL}/transaction_symptoms/${transactionID}/`)
      .then((res) => {
        this.setState({
          ["transactionSymptoms"]: res.data,
        });

        for (var i in res.data) {
          const recommendation_id = `recommendation_${res.data[i].id}`;
          const product_id = `product_${res.data[i].id}`;
          this.setState({
            [recommendation_id]: res.data[i],
            [product_id]: res.data[i],
          });
        }
      });
  };

  deleteConsumerSymptoms = (transactionSymptomID) => {
    axios
      .delete(
        `${this.props.BASE_URL}/transaction_symptoms/${transactionSymptomID}/`
      )
      .then((res) => {
        this.readConsumerSymptoms();
      });
  };

  initiate = () => {
    this.setState({
      ["transactionID"]: this.props.deviceDefaultTransaction.value,
    });

    this.readConsumerSymptoms(this.props.deviceDefaultTransaction.value);
  };

  render() {
    return (
      <div>
        <Modal
          show={this.props.deviceModal && this.props.userType === "vendor"}
          onShow={() => this.initiate()}
          onHide={() => this.props.setDeviceModal(!this.props.deviceModal)}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <div className="modal-header">
            <h5 className="modal-title" id="">
              Welcome {this.props.vendorFirstName} {this.props.vendorLastName}!
            </h5>
            <a
              href="#"
              onClick={() => {
                this.props.setDeviceModal(false);
                this.props.setDeviceConsumerListModal(
                  !this.props.deviceConsumerListModal
                );
              }}
            >
              Update Vendor Info
            </a>
          </div>
          <Modal.Body>
            <Container>
              <Row>
                <Col sm={4}>
                  <div className="col-12">
                    <CreatableSelect
                      placeholder="Device Reading"
                      isSearchable
                      isClearable
                      options={this.props.deviceTransactions}
                      defaultValue={this.props.deviceDefaultTransaction}
                      onChange={(e) => this.handleTransactionChange(e)}
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={12}>
                  <Table striped bordered hover size="lg">
                    <thead>
                      <tr>
                        <th width="25%">Symptoms</th>
                        <th width="30%">Symptoms Recommendation</th>
                        <th width="35%">Product</th>
                        <th>Image</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.transactionSymptoms.map(
                        (transactionSymptom) => (
                          <tr key={transactionSymptom.id}>
                            <td>{transactionSymptom.description}</td>
                            <td>
                              <input
                                type="text"
                                className="col-12"
                                id={transactionSymptom.id}
                                value={transactionSymptom.recommendation}
                                onBlur={(e) =>
                                  this.onBlurRecommendation(
                                    e,
                                    transactionSymptom
                                  )
                                }
                                onChange={(e) =>
                                  this.handleRecommendationChange(
                                    e,
                                    transactionSymptom
                                  )
                                }
                                placeholder="Symptoms Recommendation"
                              />
                            </td>

                            <td>
                              <CreatableSelect
                                placeholder="Select Product"
                                isSearchable
                                isClearable
                                options={this.props.productList}
                                defaultValue={transactionSymptom.product}
                                onChange={(e) =>
                                  this.handleProductChange(
                                    e,
                                    transactionSymptom
                                  )
                                }
                              />
                            </td>
                            <td>
                              {transactionSymptom.product &&
                                transactionSymptom.product.image && (
                                  <Col sm={1}>
                                    <a
                                      href={`${this.props.BASE_DOMAIN}/static/products/${transactionSymptom.product.image}`}
                                      target="_blank"
                                    >
                                      <img
                                        style={{ marginTop: "30%" }}
                                        width="70px"
                                        height="40px"
                                        src={`${this.props.BASE_DOMAIN}/static/products/${transactionSymptom.product.image}`}
                                      />
                                    </a>
                                  </Col>
                                )}
                            </td>
                          </tr>
                        )
                      )}
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row>
                <Col sm={4}>
                  <DeviceReading
                    userType={this.props.userType}
                    deviceDefaultTransaction={
                      this.props.deviceDefaultTransaction
                    }
                    BASE_URL={this.props.BASE_URL}
                    BASE_DOMAIN={this.props.BASE_DOMAIN}
                    consumerSetupAdditionalDeviceReadingKeys={
                      this.props.consumerSetupAdditionalDeviceReadingKeys
                    }
                    consumerSetupAdditionalDeviceReading={
                      this.props.consumerSetupAdditionalDeviceReading
                    }
                    consumerSetupDeviceReadingKeys={
                      this.props.consumerSetupDeviceReadingKeys
                    }
                    consumerSetupDeviceReading={
                      this.props.consumerSetupDeviceReading
                    }
                  />
                </Col>
              </Row>
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button
              size="sm"
              variant="secondary"
              onClick={() => this.props.setDeviceModal(!this.props.deviceModal)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default VendorRecommendations;
