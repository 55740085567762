/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-useless-computed-key */
import React from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import CreatableSelect from "react-select/creatable";
import { FaEdit, FaPlusCircle } from "react-icons/fa";

import TransactionAliasModal from "../TransactionAliasModal";
import NewTransactionModal from "../NewTransactionModal";
import DeviceReading from "../DeviceReading";
import { BiDevices } from "react-icons/bi";

let BASE_URL = "https://production.drfever.co/api";

class ConsumerSymptoms extends React.Component {
  state = {
    transactionID: null,
    description: "",
    transactionAliasModal: false,
    transactionAliasInput: "",
    newTransactionModal: false,
    transactionSymptoms: [],

    heart_rate: "",
    pulse_rate: "",
    voc: "",
    co2: "",
    h2o: "",
    ethonol: "",
    temperature: "",
    actual_bp1: "",
    actual_bp2: "",
  };

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.value,
    });
  };

  handleTransactionChange = (e, alias) => {
    this.setState({
      ["transactionID"]: e.value,
    });
    this.saveDefaultTransaction(e);
  };

  saveDefaultTransaction = (e, TransactionAlias) => {
    let params = {};
    if (TransactionAlias) {
      params["transactionID"] = this.state.transactionID;
      params["TransactionAlias"] = TransactionAlias;
    } else {
      params["transactionID"] = e.value;
    }

    let serialNumber = sessionStorage.getItem("serialNumber");;

    if (!serialNumber) {
      serialNumber = sessionStorage.getItem("deviceDefaultTransaction").serial_number;
    }

    axios.post(`${BASE_URL}/device/${serialNumber}/`, params).then((res) => {
      if (e) {
        sessionStorage.setItem("deviceDefaultTransaction", JSON.stringify(e))
        this.readConsumerSymptoms(e.value);
        console.log("--1");
      } else {
        this.setTransactionAliasModal(false);
        console.log("--22");
      }
    });
  };

  addConsumerSymptoms = () => {
    this.setState({
      ["description"]: "",
    });

    axios
      .put(`${BASE_URL}/transaction_symptoms/${this.state.transactionID}/`, {
        description: this.state.description,
      })
      .then((res) => {
        this.readConsumerSymptoms();
      });
  };

  readConsumerSymptoms = (transactionID) => {
    if (!transactionID) {
      transactionID = this.state.transactionID;
    }

    axios.get(`${BASE_URL}/transaction_symptoms/${transactionID}/`).then((res) => {
      this.setState({
        ["transactionSymptoms"]: res.data,
      });
    });
  };

  deleteConsumerSymptoms = (transactionSymptomID) => {
    axios.delete(`${BASE_URL}/transaction_symptoms/${transactionSymptomID}/`).then((res) => {
      this.readConsumerSymptoms();
    });
  };

  setTransactionAliasModal = (value) => {
    this.setState({
      ["transactionAliasInput"]: "",
    });

    this.setState({
      ["transactionAliasModal"]: value,
    });
  };

  setTransactionAlias = (value) => {
    this.setState({
      ["transactionAliasInput"]: value,
    });
  };

  setNewTransactionModal = (value) => {
    this.setState({
      ["newTransactionModal"]: value,
    });
  };

  saveNewTransaction = () => {
    let params = JSON.parse(sessionStorage.getItem("default_consumer_device"))
    params["data"] = {};
    let transaction_keys = ["heart_rate", "pulse_rate", "voc", "co2", "h2o", "ethonol", "temperature", "actual_bp1", "actual_bp2"];

    for (var i in transaction_keys) {
      let transaction_key = transaction_keys[i];
      params["data"][transaction_key] = parseFloat(this.state[transaction_key]);
    }

    axios.post(`${BASE_URL}/transaction/`, params).then((res) => {
      this.setNewTransactionModal(false);
    });
  };

  initiate = () => {
    this.setState({
      ["transactionID"]: sessionStorage.getItem("deviceDefaultTransaction").value,
    });

    this.readConsumerSymptoms(sessionStorage.getItem("deviceDefaultTransaction").value);
  };

  render() {
    return (
        <div>
      <div className="text-base text-black m-3 font-weight-bold">
        <BiDevices style={{ marginBottom: "5px", fontWeight: "bold" }} />{" "}
        <label to="/dashboard" style={{ fontWeight: "bold" }}>
          Devices
        </label>
      </div>
      <div className="m-4 rounded " style={{ backgroundColor: "#F6FFFf", width: "90%" }}>
      <div>
      <div>
        <div className="modal-header mb-3">
              <Button size="sm" variant="info" style={{ width: "60px" }} onClick={() => this.setTransactionAliasModal(true)}>
                <FaEdit />
              </Button>
              {sessionStorage.getItem("default_consumer_device") && (
                <Button size="sm" variant="info" style={{ width: "60px" }} onClick={() => this.setNewTransactionModal(true)}>
                  <FaPlusCircle />
                </Button>
              )}
        </div>
        <Container>
          <Row>
            <Col sm={4}>
              <div className="col-12">
                <CreatableSelect placeholder="Device Reading" isSearchable isClearable options={JSON.parse(sessionStorage.getItem("deviceTransactions"))} defaultValue={sessionStorage.getItem("deviceDefaultTransaction")} onChange={(e) => this.handleTransactionChange(e)} />
              </div>
              <DeviceReading
              deviceDefaultTransaction={
                JSON.parse(sessionStorage.getItem("deviceDefaultTransaction"))
              }
              consumerSetupAdditionalDeviceReadingKeys={
                JSON.parse(sessionStorage.getItem("addtional_device_reading_keys"))
              }
              consumerSetupAdditionalDeviceReading={
               JSON.parse(sessionStorage.getItem("additional_device_reading"))
              }
              consumerSetupDeviceReadingKeys={
                JSON.parse(sessionStorage.getItem("device_reading_keys"))
              }
              consumerSetupDeviceReading={
                JSON.parse(sessionStorage.getItem("device_reading"))
              }
              consumerID={sessionStorage.getItem("id")}
              />
            </Col>
            <Col sm={1}>
              <Button size="sm" variant="info" style={{ width: "60px", marginBottom: "3px" }} onClick={() => this.setTransactionAliasModal(true)}>
                <FaEdit />
              </Button>
            </Col>
            <Col sm={1}>
              {sessionStorage.getItem("default_consumer_device") && (
                <Button size="sm" variant="info" style={{ width: "60px", marginBottom: "3px" }} onClick={() => this.setNewTransactionModal(true)}>
                  <FaPlusCircle />
                </Button>
              )}
            </Col>
            <Col sm={6}>
            {JSON.parse(sessionStorage.getItem("symptoms")) &&
                    JSON.parse(sessionStorage.getItem("symptoms")).show && (
                      <Table striped bordered hover size="lg">
                        <thead>
                          <tr>
                            <th>{JSON.parse(sessionStorage.getItem("symptoms")).title}</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.transactionSymptoms.map(
                            (transactionSymptom) => (
                              <tr key={transactionSymptom.id}>
                                <td>{transactionSymptom.description}</td>
                                {sessionStorage.getItem("type") === "consumer" && (
                                  <td>
                                    <Button
                                      size="sm"
                                      variant="danger"
                                      onClick={() =>
                                        this.deleteConsumerSymptoms(
                                          transactionSymptom.id
                                        )
                                      }
                                    >
                                      Delete
                                    </Button>
                                  </td>
                                )}
                              </tr>
                            )
                          )}
                          <tr>
                            <td>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Symptoms Description"
                                id="description"
                                value={this.state.description}
                                onChange={this.handleChange}
                              />
                            </td>
                            <td>
                              <Button
                                size="sm"
                                variant="success"
                                onClick={() => this.addConsumerSymptoms()}
                              >
                                Add
                              </Button>
                            </td>
                          </tr>
                        </tbody>
                      </Table>
                    )}
            </Col>
          </Row>
        </Container>

        <TransactionAliasModal
          deviceDefaultTransaction={sessionStorage.getItem("deviceDefaultTransaction")}
          transactionAliasModal={this.state.transactionAliasModal}
          setTransactionAliasModal={this.setTransactionAliasModal}
          setTransactionAlias={this.setTransactionAlias}
          saveTransactionAlias={this.saveTransactionAlias}
          transactionAliasInput={this.state.transactionAliasInput}
          saveDefaultTransaction={this.saveDefaultTransaction}
        />

        <NewTransactionModal newTransactionModal={this.state.newTransactionModal} setNewTransactionModal={this.setNewTransactionModal} state={this.state} handleChange={this.handleChange} saveNewTransaction={this.saveNewTransaction} />
      </div>
      </div>
      </div>
    </div>
      
    );
  }
}

export default ConsumerSymptoms;
