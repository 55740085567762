/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import axios from "axios";
import { useForm } from "react-hook-form";
import { ErrorMessage, isEmailValid, isMobileNumber, isWebsite, SuccessMsg } from "../helpers/FormHelpers";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import "../App.css";
import { AiOutlineClose } from "react-icons/ai";
import { FaFacebookF } from "react-icons/fa";
import { FcGoogle } from "react-icons/fc";
import { MdEmail } from "react-icons/md";
import jwtDecode from "jwt-decode";
import { PhoneCodes } from "../helpers/PhoneCodes";

let BASE_URL = "https://production.drfever.co/api";

const Registration = (props) => {
  //Some state constants
  const [registerSaveBtn, setRegisterSaveBtn] = useState(false);
  const [registerSuccessMessage, showRegisterSuccessMessage] = useState(false);
  const [registerErrorAlert, showRegisterErrorAlert] = useState(false);
  const [registerErrorMessage, showRegisterErrorMessage] = useState(false);
  const [registerUserType, setRegisterUserType] = useState("business");
  const [socialApp, setSocialApp] = useState("none");
  const [userType, setUserType] = useState("Patient");
  const [signUpEmail, setSignUpEmail] = useState(false);
  const [googleModal, setGoogleModal] = useState(false);
  const [disableButton, setDisableButton] = useState(true);
  const [step, setStep] = useState(0);

  // react-hook-form instance
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({ mode: "onSubmit" });

  // form step tracker
  const handleStep = () => {
    if (step === 0) {
      setStep(1);
    }
  };

  const [businessType, setBusinessType] = useState([]);

  useEffect(() => {
    axios.get(`${BASE_URL}/business_type/`).then((res) => {
      setBusinessType(res.data);
    });
  }, [])

  // submit function
  const onRegisterSave = (data) => {
    setRegisterSaveBtn(true);
    let url = `${BASE_URL}/consumer/`;
    // let name = data.name.split(" ");
    let params = {
      coupon: data.coupon,
      type: userType,
      email: data.email,
      phone_number: data.phone_code + data.phone,
      first_name: data.first_name,
      last_name: data.last_name,
      business_name: data.buisnessName,
      business_type: data.businessType,
      buisness_website: data.buisnessWebsite,
      social_media: socialApp,
    };
    if (userType === "Patient") {
      url = `${BASE_URL}/consumer/`;
      params = {
        type: userType,
        coupon: data.coupon,
        email: data.email,
        phone_number: data.phone_code + data.phone,
        first_name: data.first_name,
        last_name: data.last_name,
        social_media: socialApp,
      };
    }

    axios
      .post(url, params)
      .then((res) => {
        showRegisterSuccessMessage(true);
        showRegisterErrorAlert(false);
        showRegisterErrorMessage("");

        setTimeout(function () {
          props.setRegisterModal(!props.registerModal);
          setStep(0);
          showRegisterSuccessMessage(false);
          showRegisterErrorAlert(false);
          setRegisterSaveBtn(false);
          setDisableButton(true);
          reset();
          setStep(0);
          setSignUpEmail(false);
        }, 2000);
      })
      .catch((e) => {
        showRegisterErrorAlert(true);
        showRegisterSuccessMessage(false);
        setRegisterSaveBtn(false);
        if (e.response.status === 400) {
          if (typeof e.response.data === "string") {
            showRegisterErrorMessage(e.response.data);
          } else {
            let error = Object.values(e.response.data)[0];
            showRegisterErrorMessage(error);
          }
        } else {
          showRegisterErrorMessage("Something went wrong. Kindly contact administrator.");
        }
      });
  };

  //Google API call and button render
  useEffect(() => {
    /* global google */
    setTimeout(() => {
      google.accounts.id.initialize({
        client_id: "572969413316-2dck4j58odfa82nt4e3kvpvvn1u62o7i.apps.googleusercontent.com",
        callback: googleCallBack,
      });
      google.accounts.id.renderButton(document.getElementById("googleSignup"), {
        type: "standard",
        size: "large",
        shape: "rectangular",
        logo_alignment: "center",
        width: 230,
      });
    }, 100);
  });

  // callback handler for google signUp
  const googleCallBack = (response) => {
    setSocialApp("google");
    console.log(response);
    const userObject = jwtDecode(response.credential);
    console.log(userObject);
    setValue("first_name", userObject.given_name);
    setValue("last_name", userObject.family_name);
    setValue("email", userObject.email);
    setGoogleModal(false);
    setSignUpEmail(!signUpEmail);
  };

  // reset everything on closing registeration modal
  const cancelHandle = () => {
    setStep(0);
    props.setRegisterModal(!props.registerModal);
    setSignUpEmail(false);
    showRegisterErrorAlert(false);
    setDisableButton(true);
    reset();
  };

  // Facebook login response handler
  const responseFacebook = (response) => {
    let name = response.name.split(" ");
    setValue("first_name", name[0]);
    setValue("last_name", name[1]);
    setSocialApp("facebook");
    setValue("email", response.email);
    setSignUpEmail(true);

    // Login failed
    if (response.status === "unknown") {
      alert("Login failed!");
      return false;
    }
  };

  // validation functions
  const isValidEmail = (e) => {
    if (!e || isEmailValid(e)) {
      return true;
    }
    return "Email Address is invalid";
  };

  const websiteValidate = (e) => {
    if (!e || isWebsite(e)) {
      return true;
    }
    return "Website is invalid";
  };

  const isValidPhoneNumber = (e) => {
    if (!e || isMobileNumber(e)) {
      return true;
    }
    return "Phone Number is invalid";
  };

  // Sign up  - Sign in switch handler
  const switchHandle = () => {
    props.setRegisterModal(!props.registerModal);
    props.setLoginModal(!props.LoginModal);
  };
  return (
    <div>
      <li>
        <a
          href="#register"
          onClick={() => {
            props.openRegisterModal();
          }}
        >
          Register
        </a>
      </li>
      <Modal size="lg" centered show={props.registerModal} onHide={() => props.setRegisterModal(!props.registerModal)} aria-labelledby="contained-modal-title-vcenter">
        <Modal.Body className="container-fluid">
          <div className="exit" onClick={() => cancelHandle()}>
            <AiOutlineClose />
          </div>
          <Container style={{ textAlign: "right", display: "flex", height: "30rem" }}>
            <img alt="register" src="./registerPhoto.jpg" width="300" height="50%" className="img-fluid rounded registerImg"></img>
            <Container className="text-center text-fluid fluid" style={{ marginTop: "2rem" }}>
              <div className="col-sm-12">
                <h5>Sign Up</h5>
                <br />
              </div>
              {step === 0 && !signUpEmail && (
                <Container className="container-fluid  registerationButtons" style={{ width: "300px", marginTop: "3px" }}>
                  <form onSubmit={handleSubmit(() => setSignUpEmail(true))}>
                    <Row>
                      <div className=" mt-4 col-14" style={{ marginBottom: "3px" }}>
                        <input {...register("email", { required: "Email Address is Required", validate: isValidEmail })} type="email" placeholder="Email" className="animationInput animate" style={{ width: "100%" }} aria-describedby="basic-addon1" />
                      </div>
                      {errors.email && <ErrorMessage message={errors.email.message} />}
                    </Row>
                    <Row style={{ marginTop: "10px" }}>
                      <button type="submit" className="emailBtn">
                        <span style={{ padding: "2px", width: "10px", position: "inherit" }}>
                          <MdEmail size="20px" />
                          &nbsp;&nbsp;
                        </span>
                        Sign Up
                      </button>
                    </Row>
                  </form>
                  <br />
                  <div class="line">
                    <span>or</span>
                  </div>
                  <Row style={{ marginTop: "20px" }}>
                    <FacebookLogin
                      appId="897101105005313"
                      textButton=" Sign Up with Facebook"
                      autoLoad={false}
                      fields="name,email,picture"
                      scope="public_profile,email,user_friends"
                      callback={responseFacebook}
                      render={(renderProps) => (
                        <button className="facebook" onClick={renderProps.onClick}>
                          <FaFacebookF /> Sign Up with Facebook
                        </button>
                      )}
                    />
                  </Row>
                  <Row style={{ marginTop: "10px" }}>
                    <button onClick={() => setGoogleModal(true)} className="google">
                      <span style={{ padding: "2px", width: "10px", position: "inherit" }}>
                        <FcGoogle size="20px" />
                        &nbsp;&nbsp;
                      </span>
                      Sign Up with Google
                    </button>
                  </Row>
                  <br />
                  <div className="have-account">
                    Already have an account? &nbsp;
                    <a href="#" onClick={() => switchHandle()}>
                      Sign In
                    </a>{" "}
                  </div>
                </Container>
              )}

              {step === 0 && signUpEmail && (
                <form onSubmit={handleSubmit(userType === "Vendor" ? handleStep : onRegisterSave)}>
                  <br />
                  <br />
                  <div>
                    <div className="form-group" onChange={(e) => setRegisterUserType(e.target.value)}>
                      <input onClick={() => setUserType("Patient")} className="form-check-input animate" {...register("userType", { required: "User Type is Required" })} type="radio" value="Patient" name="user_type" id="user_type_Patient" checked={userType === "Patient"} />
                      <label for="user_type_Patient" className="animate">
                        {" "}
                        &nbsp;Patient
                      </label>{" "}
                      &nbsp; &nbsp;
                      <input checked={userType === "Vendor"} onClick={() => setUserType("Vendor")} className="form-check-input animate" {...register("userType", { required: "User Type is Required" })} type="radio" value="Vendor" name="user_type" id="user_type_Vendor" />
                      <label for="user_type_Vendor" className="animate">
                        {" "}
                        &nbsp;Vendor
                      </label>{" "}
                      &nbsp;
                    </div>
                    {errors.userType && <ErrorMessage message={errors.userType.message} />}
                    <br />
                    <div className="name">
                      <div className="name-div">
                        <input
                          {...register("first_name", { required: "First Name is required" })}
                          className="animationInput2 animate"
                          type="text"
                          placeholder="First Name"
                          width="auto"
                          onKeyPress={(event) => {
                            if (/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </div>
                      <div className="name-div">
                        <input
                          {...register("last_name", { required: "Last Name is required" })}
                          className="animationInput2 animate"
                          type="text"
                          placeholder="Last Name"
                          width="auto"
                          onKeyPress={(event) => {
                            if (/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                        />
                      </div>
                    </div>
                    <div className="name-error">
                      <Row>
                        <Col style={{ width: "10%", padding: "0" }}>{errors.first_name && <ErrorMessage message={errors.first_name.message} />}</Col>
                        <Col>{errors.last_name && <ErrorMessage message={errors.last_name.message} />}</Col>
                      </Row>
                    </div>
                    <div className="mt-4" style={{ display: "flex", justifyContent: "center" }}>
                      <select className="phoneDropdown" {...register("phone_code", { required: false })}>
                        {PhoneCodes.map((code) => (
                          <option value={code.dial_code}>
                            &nbsp;{code.flag}&nbsp;{code.name}&nbsp;{code.dial_code}
                          </option>
                        ))}
                      </select>
                      <input style={{ width: "50%" }} {...register("phone", { required: "Phone Number is required", validate: isValidPhoneNumber })} type="tel" placeholder="Phone Number" className="animationInput animate" />
                    </div>
                    {errors.phone && <ErrorMessage message={errors.phone.message} />}
                    {userType === "Patient" && (
                      <div>
                        <br />
                        <div className="col-12">
                          <input {...register("coupon", { required: false })} type="text" className="animationInput animate" placeholder="Coupon" />
                        </div>
                        {errors.coupon && <ErrorMessage message={errors.coupon.message} />}
                      </div>
                    )}
                    <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }}>
                      <input
                        type="checkbox"
                        onChange={() => {
                          setDisableButton(!disableButton);
                        }}
                      />
                      <label style={{ fontSize: "13px" }}>
                        {" "}
                        &nbsp; I agree to <a href="/privacy">Terms and Conditions</a>
                      </label>
                    </div>
                    <br />
                    <div className="have-account">
                      Already have an account? &nbsp;
                      <a href="#" onClick={() => switchHandle()}>
                        Sign In
                      </a>{" "}
                    </div>
                  </div>
                  {userType === "Vendor" && (
                    <div className="registerationButton2">
                      <button id="submit-button" className="btn btn-light" type="submit" variant="success" style={{ width: 100 }} disabled={registerSaveBtn || !signUpEmail || disableButton} onClick={() => {}}>
                        Next
                      </button>
                    </div>
                  )}
                  {userType === "Patient" && (
                    <div className="registerationButton2">
                      <button id="submit-button" className="btn btn-light" type="submit" variant="success" style={{ width: 100 }} disabled={registerSaveBtn || !signUpEmail || disableButton} onClick={() => {}}>
                        Submit
                      </button>
                    </div>
                  )}
                </form>
              )}
              {step === 1 && (
                <div>
                  <form onSubmit={handleSubmit(onRegisterSave)}>
                    <br />
                    <br />
                    <br />
                    {registerUserType === "Vendor" && (
                      <div>
                        <div id="dropdown" className="col-12">
                          <select className="animationDropdown animate" {...register("businessType", { required: "Business Type is required" })} options={props.businessType}>
                            <option value={null} key={0}>
                              Select Business
                            </option>
                            {businessType.map((type) => (
                              <option value={type.value} key={type.id}>
                                {type.label}
                              </option>
                            ))}
                          </select>
                        </div>
                        <br />
                        <div id="business-field" className="col-12">
                          <input {...register("buisnessName", { required: "Business Name is required" })} type="text" className="animationInput animate" placeholder="Business Name" />
                        </div>
                        {errors.buisnessName && <ErrorMessage message={errors.buisnessName.message} />}
                        <br />
                        <div className="col-12">
                          <input {...register("buisnessWebsite", { required: false, validate: websiteValidate })} type="text" className="animationInput animate" placeholder="Business Website (Optional)" />
                        </div>
                        {errors.buisnessWebsite && <ErrorMessage message={errors.buisnessWebsite.message} />}
                      </div>
                    )}
                    <div className="registerationButton">
                      <button
                        id="submit-button"
                        className="btn btn-light"
                        type="button"
                        variant="success"
                        style={{ width: "100%" }}
                        disabled={registerSaveBtn || !signUpEmail}
                        onClick={() => {
                          setStep(0);
                          setDisableButton(true);
                        }}
                      >
                        Previous
                      </button>
                      <button
                        id="submit-button"
                        className="btn btn-light"
                        type="submit"
                        variant="success"
                        style={{ width: "100%" }}
                        disabled={registerSaveBtn || !signUpEmail}
                        onClick={() => {
                          console.log(step);
                        }}
                      >
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              )}
              {registerSuccessMessage && <SuccessMsg message={"Device Successfully requested."} />}
              {registerErrorAlert && <ErrorMessage message={registerErrorMessage} />}
            </Container>
          </Container>
        </Modal.Body>
      </Modal>
      <Modal size="sm" show={googleModal} onHide={() => setGoogleModal(!googleModal)}>
        <Modal.Body>
          <div style={{ display: "flex", gap: "10px", alignItems: "center", justifyContent: "center" }}>
            <div>
              <div id="googleSignup" data-context="signup"></div>
            </div>
            <div className="exit" onClick={() => setGoogleModal(!googleModal)}>
              <AiOutlineClose />
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Registration;
