import React from 'react'
import { useFormContext } from 'react-hook-form'
import { ErrorMessage } from '../../../helpers/FormHelpers'
import "bootstrap/dist/css/bootstrap.min.css";

function DeviceReading() {
    const {register, formState: {errors}} = useFormContext()
    return (
      <div>
         <div className="m-3 form-check">
    <input {...register("showDeviceReading")} type="checkbox" className="form-check-input" id="exampleCheck1"/>
    <label className="form-check-label" htmlFor="exampleCheck1">Show</label>
  </div>
        <div className="flex-container">
          <div className="m-3 mt-3 w-75 input-field">
            <label htmlFor="exampleInputEmail1" className="form-label">
              Title
            </label>
            <input type="text" {...register('deviceReadingTitle', {required: false})} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
            {errors.deviceReadingTitle && <ErrorMessage message={errors.deviceReadingTitle.message} />}
          </div>
          <div className="m-3 w-75 input-field">
            <label htmlFor="exampleInputEmail1" className="form-label">
              CO2
            </label>
            <input type="text" {...register('co2', {required: false})} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
            {errors.co2 && <ErrorMessage message={errors.co2.message} />}
          </div>
        </div>
        <hr />
        <div className="flex-container">
          <div className="m-3 mt-3 w-75 input-field">
            <label htmlFor="exampleInputEmail1" className="form-label">
             Ethanol
            </label>
            <input type="text" {...register('ethanol', {required: false})} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
            {errors.ethanol && <ErrorMessage message={errors.ethanol.message} />}
          </div>
          <div className="m-3 mt-3 w-75 input-field">
          <label htmlFor="exampleInputEmail1" className="form-label">
            H2O
          </label>
          <input type="text" {...register('h2o', {required: false})} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
          {errors.h2o && <ErrorMessage message={errors.h2o.message} />}
        </div>
        </div>
        <hr />
        <div className="flex-container">
          <div className="m-3 mt-3 w-75 input-field">
            <label htmlFor="exampleInputEmail1" className="form-label">
                Heart Rate
            </label>
            <input type="text" {...register('heartRate', {required: false})} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
            {errors.heartRate && <ErrorMessage message={errors.heartRate.message} />}
          </div>
          <div className="m-3 mt-3 w-75 input-field">
          <label htmlFor="exampleInputEmail1" className="form-label">
            Pulse Rate
          </label>
          <input type="text" {...register('pulseRate', {required: false})} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
          {errors.pulseRate && <ErrorMessage message={errors.pulseRate.message} />}
        </div>
        </div>
        <hr />
        <div className="flex-container">
          <div className="m-3 mt-3 w-75 input-field">
            <label htmlFor="exampleInputEmail1" className="form-label">
            Temperature
            </label>
            <input type="text" {...register('temperature', {required: false})} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
            {errors.temperature && <ErrorMessage message={errors.temperature.message} />}
          </div>
          <div className="m-3 mt-3 w-75 input-field">
          <label htmlFor="exampleInputEmail1" className="form-label">
           VOC
          </label>
          <input type="text" {...register('voc', {required: false})} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
        </div>
        </div>
        <br />
      </div>
  )
}

export default DeviceReading