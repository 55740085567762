import React from "react";
import { useFormContext } from "react-hook-form";
import { ErrorMessage } from "../../../helpers/FormHelpers";

function DeviceDetails() {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <div>
      <div className="flex-container">
        <div className="m-3 mt-3 w-75 input-field">
          <label htmlFor="exampleInputEmail1" className="form-label">
            Title
          </label>
          <input type="text" {...register("deviceDetailsTitle", { required: false })} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
          {errors.deviceDetailsTitle && <ErrorMessage message={errors.deviceDetailsTitle.message} />}
        </div>
        <div className="m-3 w-75 input-field">
          <label htmlFor="exampleInputEmail1" className="form-label">
            Serial
          </label>
          <input type="text" {...register("serial", { required: false })} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
          {errors.serial && <ErrorMessage message={errors.serial.message} />}
        </div>
      </div>
      <hr />
      <div className="flex-container">
        <div className="m-3 mt-3 w-75 input-field">
          <label htmlFor="exampleInputEmail1" className="form-label">
            Others
          </label>
          <input type="text" {...register("otherDetails", { required: false })} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
          <div className="mt-2 form-check">
            <input {...register("showDeviceReading")} type="checkbox" className="form-check-input" id="exampleCheck1" />
            <label className="form-check-label" htmlFor="exampleCheck1">
              Show
            </label>
          </div>
          {errors.otherDetails && <ErrorMessage message={errors.otherDetails.message} />}
        </div>
        <div className="m-3 mt-3 w-75 input-field">
          <label htmlFor="exampleInputEmail1" className="form-label">
            Vendor
          </label>
          <input type="text" {...register("vendor", { required: false })} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
          <div className="mt-2 form-check">
            <input {...register("showDeviceReading")} type="checkbox" className="form-check-input" id="exampleCheck1" />
            <label className="form-check-label" htmlFor="exampleCheck1">
              Show
            </label>
          </div>
          {errors.Vendor && <ErrorMessage message={errors.Vendor.message} />}
        </div>
      </div>
      <hr />
      <div className="flex-container">
        <div className="m-3 mt-3 w-75 input-field">
          <label htmlFor="exampleInputEmail1" className="form-label">
            Symptoms
          </label>
          <input type="text" {...register("otherSymptoms", { required: false })} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
          {errors.otherSymptoms && <ErrorMessage message={errors.otherSymptoms.message} />}
        </div>
      </div>
      <br />
    </div>
  );
}

export default DeviceDetails;
